import React, { useState } from 'react';
import styles from './styles/TextInput.module.scss';

interface TextInputEditProps {
  text: string;
  label?: string;
  placeholder?: string;
  size?: 'SMALL' | 'MEDIUM' | 'LARGE';
  tight?: boolean;
  updateEachStroke: boolean;
  disabled?: boolean;
  cancel?: () => void;
  setText: (text: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextInputEdit: React.FC<TextInputEditProps> = ({
  text,
  label,
  placeholder,
  updateEachStroke,
  size,
  tight,
  disabled,
  cancel,
  setText,
  onKeyDown,
}) => {
  const [inputValue, setInputValue] = useState<string>(text);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (updateEachStroke) {
      setText(newValue);
    }
  };

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
  };

  const handleKeyStroke = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setText(inputValue);
    }
    if (event.key === 'Escape') {
      handleCancel();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const getStyle = () => {
    if (size) {
      switch (size) {
        case 'SMALL':
          return styles.small;
        case 'MEDIUM':
          return styles.medium;
        case 'LARGE':
          return styles.large;
      }
    }
  };

  return (
    <div className={`${styles.TextInput}`}>
      {label && <label htmlFor="textInput">{label}</label>}
      <input
        type="text"
        id="textInput"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyStroke}
        onBlur={handleCancel}
        autoComplete="off"
        placeholder={placeholder}
        className={`${tight ? styles.tight : ''} ${size ? getStyle() : ''}`}
        disabled={disabled}
      />
    </div>
  );
};

export default TextInputEdit;
