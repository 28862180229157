import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer,
  useGridApiRef,
} from '@mui/x-data-grid';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  addExclusion,
  deleteExclusion,
  editExclusion,
  optimalAPI,
} from '../../api';
import { Exclusion } from '../../models';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    const exclusion = new Exclusion();
    exclusion.id = id;
    console.log('Attempting to set new exclusion', exclusion);

    setRows((oldRows) => [...oldRows, exclusion]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'personalNumber' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Lägg till exklusion
      </Button>
    </GridToolbarContainer>
  );
}

interface EditableExclusionsProps {
  initialRows: GridRowsProp;
  refNo: string;
}

export default function EditeableExclusions({
  initialRows,
  refNo,
}: EditableExclusionsProps) {
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState(initialRows);

  const [file, setFile] = React.useState(null);
  const [fileContent, setFileContent] = React.useState([]);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please upload a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('refNo', refNo);

    try {
      const response = await optimalAPI.uploadExclusionFile(formData);

      //setFileContent(response.lines);

      response.lines.forEach((line: any) => {
        apiRef.current.updateRows([line]);
      });

      setFile(null);
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      //setRows(response.lines);
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  React.useEffect(() => {
    setRows(initialRows); // Assuming setRows is used to manage the table data state
  }, [initialRows]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //const apiRef = useGridApiRef();

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id: GridRowId) => {
    setRowModesModel((prevRowModesModel) => ({
      ...prevRowModesModel,
      [id]: { mode: GridRowModes.View }, // This triggers processRowUpdate automatically
    }));

    // Wait for the mode change to complete (optional)
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  const handleDeleteClick = async (deleteRow: any) => {
    console.log('Deleting row: ', deleteRow);

    if (deleteRow._id) {
      const result = await deleteExclusion(refNo, deleteRow as Exclusion);
      if (deleteRow.id) {
        apiRef.current.updateRows([{ id: deleteRow.id, _action: 'delete' }]);
      } else {
        apiRef.current.updateRows([{ id: deleteRow._id, _action: 'delete' }]);
      }
    } else {
      apiRef.current.updateRows([{ id: deleteRow.id, _action: 'delete' }]); // Row hasn't yet been added to DB
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && !editedRow.isNew) {
      //setRows(rows.filter((row) => row.id !== id));
      apiRef.current.updateRows([{ id: editedRow.id, _action: 'delete' }]);
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log('Processing row update!', newRow);

    newRow.rowError = undefined;

    const pnrRegex = /^\d{6,8}[-|(\s)]{0,1}\d{4}$/; // Regular expression to allow only numbers and spaces
    if (!pnrRegex.test(newRow.personalNumber)) {
      if (!newRow.rowError) {
        newRow.rowError = {};
      }
      newRow.rowError.personalNumber = true;
      console.log('Row error set!');
    }

    let updatedExclusion: Exclusion;

    if (newRow.rowError) {
      console.log('Row had error.');
      apiRef.current.updateRows([newRow]);
    } else {
      console.log('Row did not have error', newRow);

      //apiRef.current.setState(newRow.id, (state:any) => ({ ...state }));

      if (newRow._id) {
        // Update existing row
        updatedExclusion = await editExclusion(newRow as Exclusion, refNo);
        if (newRow.id) {
          updatedExclusion.id = newRow.id;
        }
        apiRef.current.updateRows([updatedExclusion]);
      } else {
        updatedExclusion = await addExclusion(newRow as Exclusion, refNo);
        updatedExclusion.id = newRow.id;
        apiRef.current.updateRows([updatedExclusion]);
      }

      console.log('Rows', rows);
      console.log('Updated exclusion', updatedExclusion);

      return updatedExclusion;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'personalNumber',
      headerName: 'Personnummer',
      width: 800,
      editable: true,
      cellClassName: (params) => {
        /*const personalNumberCell = document.querySelector(`[data-id="${params.row.id || params.row._id}"] [data-field="personalNumber"]`);
        console.log("PNRC", personalNumberCell);
        if (personalNumberCell && personalNumberCell.classList.contains('error-cell')) {
            personalNumberCell.classList.remove('error-cell');
        }*/
        if (params.row.rowError && params.row.rowError.personalNumber) {
          return 'error-cell'; // Apply error class if there's an error on the personalNumber field
        }
        return '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box>
      <input
        type="file"
        accept=".txt"
        onChange={handleFileChange}
        ref={inputRef}
      />
      <Button variant="outlined" color="primary" onClick={handleUpload}>
        Upload
      </Button>

      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowId={(row) => (row.id ? row.id : row._id)}
        disableColumnMenu
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        apiRef={apiRef}
      />
    </Box>
  );
}
