import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../GlobalContext';
import { optimalAPI } from '../../api';
import googleConfig from '../../conf/google.json';
import {
  Coordinates,
  GeoCoordinates,
  GeoShape,
  MapSettings,
  MarkerData,
  ProximityMarkerSettings,
  Selection,
  SelectionCount,
  Shape,
} from '../../models';
import { optimalUtil } from '../../util';
import { MapMarkerAltIcon, MapMarkerIcon } from '../misc/icons';
import MapAreaList from './map/MapAreaList';
import MapSearch from './map/MapSearch';
import OptimalGoogleMap from './map/OptimalGoogleMap';
import ProximityEntry from './map/ProximityEntry';
import ProximitySettings from './map/ProximitySettings';
import StoredMapAreaList from './map/StoredMapAreaList';
import styles from './styles/Geo.module.scss';

interface GeoProps {
  googleLoaded: boolean;
  geo: GeoShape[];
  active: boolean;
  selectionCount: SelectionCount;
  selection: Selection;
  setGeo: (shapes: GeoShape[]) => void;
  updateProximity: (
    limit: number,
    proximity: GeoCoordinates | undefined,
  ) => void;
}

const Geo: React.FC<GeoProps> = ({
  googleLoaded,
  geo,
  active,
  selectionCount,
  selection,
  setGeo,
  updateProximity,
}) => {
  // Global stuff
  const context = useContext(GlobalContext)!;
  const { globalObject } = context;
  const refNo = globalObject.refNo;

  // Geo selection state
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [markerData, setMarkerData] = useState<MarkerData | null>(null);

  // Map action states
  const [mapCenter, setMapCenter] = useState<Coordinates>(
    googleConfig.defaultStartCoordinates,
  );
  const [mapZoom, setMapZoom] = useState<number>(googleConfig.defaultZoom);
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | null>(
    null,
  );

  // Component states
  const [editShape, setEditShape] = useState<Shape | null>(null);
  const [placeNeedle, setPlaceNeedle] = useState<boolean>(false);
  const [showProximitySettings, setShowProximitySettings] =
    useState<boolean>(false);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  // Settings
  const [mapSettings, setMapSettings] = useState<MapSettings>({});
  const [storedShapes, setStoredShapes] = useState<Shape[]>([]);

  const initGeo = useCallback(async () => {
    console.log('Setting up Geo');
    if (googleLoaded) {
      if (!settingsLoaded) {
        let settings = await optimalAPI.getMapSettings(refNo);
        setMapSettings(settings);
        if (settings.mapAreas && settings.mapAreas.length) {
          setStoredShapes(optimalUtil.convertMapAreaToShape(settings.mapAreas));
        }
        setSettingsLoaded(true);
        console.log('Geo settings loaded');
      }
      if (geo.length > 0) {
        console.log('Selection contains geo - updating');
        updateShapes(geo);
        setMapBounds(optimalUtil.getLatLngBoundsFromShapes(shapes));
      } else if (selection.proximity) {
        console.log('Selection contains proximity - updating');
        const center = optimalUtil.convertGeoCoordinates(selection.proximity);
        setMapCenter(center);
        if (selectionCount && selectionCount.proximityRadius) {
          setMapBounds(
            optimalUtil.getCircleBounds(center, selectionCount.proximityRadius),
          );
        }
      } else {
        if (mapSettings.center && mapSettings.zoom) {
          setMapCenter(optimalUtil.convertGeoCoordinates(mapSettings.center));
          setMapZoom(mapSettings.zoom);
        } else {
          console.log(
            'No data to determine zoom and center for map avail, this needs to be fixed',
          );
        }
      }
    }
  }, [geo, googleLoaded]);

  useEffect(() => {
    if (active) {
      console.log('init geo');
      initGeo();
    }
  }, [initGeo, active]);

  // On render check if proximity is set in selection
  useEffect(() => {
    console.log('Proximity radius update');
    if (selection.proximity && selectionCount.proximityRadius) {
      console.log('Setting proximity data');
      setMarkerData({
        coordinates: {
          lat: selection.proximity.lat,
          lng: selection.proximity.lon,
        },
        proximityRadius: selectionCount.proximityRadius,
        addressStr: null,
        limit: selection.limit,
        type: 'PROXIMITY',
      });
    } else if (selection.proximity && !selectionCount.proximityRadius) {
      console.log(
        'Setting limit and proximity:',
        selection.limit,
        selection.proximity,
      );
      updateProximity(selection.limit, selection.proximity);
    } else if (!selectionCount.proximityRadius && markerData) {
      setMarkerData({
        ...markerData,
        type: 'SELECTION',
        proximityRadius: undefined,
        addressStr: null,
      });
    }
  }, [selectionCount.proximityRadius]);

  useEffect(() => {
    console.log('Shapes length changed', shapes.length);
  }, [shapes]);

  const updateGeo = useCallback(() => {
    const isDifferent =
      shapes.length !== geo.length ||
      shapes.some(
        (newShape, index) => !optimalUtil.isSameShape(geo[index], newShape),
      );

    // Only call setGeo if there are differences
    if (isDifferent) {
      console.log('Geo update', geo, shapes);
      setGeo(optimalUtil.convertShapeToGeoShape(shapes));
    }
  }, [shapes, setGeo, geo]);

  // Effect hook monitoring shapes that affect Geo
  useEffect(() => {
    updateGeo();
  }, [shapes, updateGeo]);

  const updateProximityMarker = useCallback(() => {
    if (
      markerData &&
      selectionCount &&
      selectionCount.proximityRadius &&
      selectionCount.proximityRadius !== markerData.proximityRadius
    ) {
      setMarkerData({
        ...markerData,
        proximityRadius: selectionCount.proximityRadius,
        type: 'PROXIMITY',
      });
    }
  }, [markerData, selectionCount]);

  // Effect hook monitoring proximity
  useEffect(() => {
    if (selectionCount.proximityRadius && selectionCount.proximityRadius > 0) {
      updateProximityMarker();
    }
  }, [selectionCount, updateProximityMarker]);

  // When place is selected with text search
  const handlePlaceSelected = (
    coordinates: Coordinates,
    bounds: google.maps.LatLngBounds | null,
    address: string | null,
  ) => {
    console.log('handle place selected');
    setMapCenter(coordinates);
    if (bounds != null) {
      setMapBounds(bounds);
    }
    setMarkerData({
      addressStr: address,
      coordinates: coordinates,
      limit: 100,
      type: 'PROXIMITY',
    });
  };

  // Pan map to shape
  const handlePanToShape = (shape: Shape) => {
    console.log('pan to shape');
    const latLngBounds = optimalUtil.getLatLngBoundsFromShape(shape);
    setMapBounds(latLngBounds);
  };

  // Save shape to favorites
  const handleStoreShape = async (shape: Shape) => {
    console.log('store shape');
    try {
      await optimalAPI.saveMapAreas(
        optimalUtil.convertShapeToMapArea([...storedShapes, shape]),
        refNo,
      );
      setStoredShapes([...storedShapes, shape]);
      return true;
    } catch (error: any) {
      console.error('Store shape failed');
    }
    return false;
  };

  // Remove shape from favorites
  const handleRemoveStoredShape = async (shape: Shape) => {
    console.log('remove stored shape');
    const purgedShapes = storedShapes.filter((tmpShape) => {
      return tmpShape.uuid !== shape.uuid;
    });
    try {
      await optimalAPI.saveMapAreas(
        optimalUtil.convertShapeToMapArea(purgedShapes),
        refNo,
      );
      setStoredShapes(purgedShapes);
    } catch (error: any) {
      console.error('Delete shape failed.');
    }
  };

  // MarkerSettings har ändrats
  const handleProximitySettings = (settings: ProximityMarkerSettings) => {
    console.log('Proximity settings update');
    if (markerData !== null) {
      if (settings.type === 'PROXIMITY') {
        updateProximity(
          settings.size,
          optimalUtil.convertCoordinates(markerData.coordinates),
        );
        setMarkerData({
          ...markerData,
          limit: settings.size,
          type: 'PROXIMITY',
        });
      } else {
        updateProximity(1000, undefined);
        setMarkerData({
          ...markerData,
          type: 'SELECTION',
          limit: settings.size,
        });
      }
      if (settings.done) {
        setShowProximitySettings(!settings.done);
      }
    }
  };

  // Edit proximity har klickats, visa overlay
  const handleEditProximity = (edit: boolean) => {
    console.log('edit proximity');
    setShowProximitySettings(edit);
  };

  // Marker har placerats
  const handleSetMarkerData = (mapMarkerData: MarkerData) => {
    console.log('Här kommer marker data', mapMarkerData);
    setMarkerData({
      coordinates: mapMarkerData.coordinates,
      addressStr: '',
      limit: mapMarkerData.type === 'PROXIMITY' ? selection.limit : 500,
      type: mapMarkerData.type,
    });
    if (mapMarkerData.type === 'SELECTION') {
      setPlaceNeedle(true);
      setMapBounds(
        optimalUtil.getCircleBounds(
          mapMarkerData.coordinates,
          mapMarkerData.limit,
        ),
      );
    }
    setShowProximitySettings(true);
  };

  function updateShapes(geo: GeoShape[]) {
    console.log('Update shapes', geo, shapes);
    setShapes((prevShapes) => {
      const newShapes = geo.filter((gShape) => {
        return !prevShapes.some((shape) =>
          optimalUtil.isSameShape(gShape, shape),
        );
      });
      // If no new shapes, return prevState to avoid rerenders
      if (newShapes.length === 0) {
        console.log('No changes found in geo, no rerender');
        return prevShapes;
      }
      const newShapeObjects = optimalUtil.convertGeoShapesToShapes(newShapes);
      // Return the updated shapes array
      return [...prevShapes, ...newShapeObjects];
    });
  }

  // Add a shape to shapes array
  const handleAddShape = (shape: Shape) => {
    console.log('ADD SHAPE', shapes, shape);
    //setShapes((prevShapes) => [...prevShapes, shape]);
    setShapes([...shapes, shape]);
  };

  // Remove shape with uuid
  const handleRemoveShape = (uuid: string) => {
    console.log('====> remove shape');
    setShapes((prevShapes) =>
      prevShapes.filter((shape) => shape.uuid !== uuid),
    );
  };

  const handleUpdateShape = (updatedShape: Shape) => {
    console.log('UPDATE shape');
    setShapes((prevShapes) =>
      prevShapes.map((shape) => {
        if (shape.uuid === updatedShape.uuid) {
          if (updatedShape.type === 'polygon' && updatedShape.path) {
            return { ...shape, path: updatedShape.path };
          } else if (
            updatedShape.type === 'circle' &&
            updatedShape.center &&
            updatedShape.radius !== undefined
          ) {
            return {
              ...shape,
              center: updatedShape.center,
              radius: updatedShape.radius,
            };
          }
        }
        console.log('Tried to update modified shape, but no uuid match');
        return shape; // Return the original shape if it doesn't match the uuid
      }),
    );
  };

  return (
    <>
      {active && (
        <div className={`${styles.criterionArea} ${styles.Geo}`}>
          <h3>
            <MapMarkerIcon />
            &nbsp;Karturval:
          </h3>
          <span className={styles.row}>
            <MapSearch
              onPlaceSelected={handlePlaceSelected}
              googleLoaded={googleLoaded}
            />
            {!placeNeedle && markerData && markerData.addressStr && (
              <span className={styles.placeMarker}>
                <MapMarkerAltIcon />
                <span>
                  <strong>Placera nål:</strong>
                  <br />
                  <span
                    className={styles.searchedAddress}
                    onClick={() => {
                      setPlaceNeedle(true);
                      setShowProximitySettings(true);
                    }}
                  >
                    {markerData.addressStr}
                  </span>
                </span>
              </span>
            )}
          </span>
          <div className={styles.mapWrapper}>
            <OptimalGoogleMap
              mapCenter={mapCenter}
              mapZoom={mapZoom}
              mapBounds={mapBounds}
              googleLoaded={googleLoaded}
              shapes={shapes}
              editShape={editShape}
              placeNeedle={placeNeedle}
              markerData={markerData}
              setMarkerData={handleSetMarkerData}
              addShape={handleAddShape}
              removeShape={handleRemoveShape}
              updateShape={handleUpdateShape}
            />
            <ProximitySettings
              show={showProximitySettings}
              limit={selection.limit ? selection.limit : 100}
              setSettings={handleProximitySettings}
            />
          </div>
          <div className={styles.row}>
            <ProximityEntry
              markerData={markerData}
              editProximity={handleEditProximity}
            />
          </div>
          <div className={styles.row}>
            <MapAreaList
              shapes={shapes}
              editShape={editShape}
              onPanToShape={handlePanToShape}
              storeShape={handleStoreShape}
              removeShape={handleRemoveShape}
              setEditShape={setEditShape}
            />
            <StoredMapAreaList
              storedShapes={storedShapes}
              onPanToShape={handlePanToShape}
              addShape={handleAddShape}
              removeStoredShape={handleRemoveStoredShape}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Geo;
