import React from 'react';
import { UserIcon } from '../misc/icons';
import AgeAndBirthDate from './AgeAndBirthDate';
import Gender from './Gender';
import styles from './styles/CriteriaGroup.module.scss';

interface PersonProps {
  active: boolean;
  age: string[];
  birthYearAndMonth: string[];
  birthMonth: string[];
  gender: ('M' | 'K')[];
  updateSelectionCriteria: (path: string[], value: any) => void;
}

const PersonCriteriaGroup: React.FC<PersonProps> = ({
  active,
  age,
  birthYearAndMonth,
  birthMonth,
  gender,
  updateSelectionCriteria,
}) => {
  const setAge = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'age'], items);
  };
  const setGender = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'gender'], items);
  };
  const setBirthMonth = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'birthMonth'], items);
  };
  const setBirthYearAndMonth = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'birthYearAndMonth'], items);
  };
  return (
    <>
      {active && (
        <div className={`${styles.criterionArea}`}>
          <h3>
            <UserIcon />
            &nbsp;Personurval:
          </h3>
          <AgeAndBirthDate
            age={age ?? []}
            active={true}
            setAge={setAge}
            birthMonth={birthMonth ?? []}
            setBirthMonth={setBirthMonth}
            birthYearAndMonth={birthYearAndMonth ?? []}
            setBirthYearAndMonth={setBirthYearAndMonth}
          />
          <Gender active={true} gender={gender ?? []} setGender={setGender} />
        </div>
      )}
    </>
  );
};

export default PersonCriteriaGroup;
