import React from 'react';
import Icon from '../../misc/Icon';
import styles from './styles/WarningMsg.module.scss';

interface WarningMsgProps {
  msg: string;
  style?: {};
}

const WarningMsg: React.FC<WarningMsgProps> = ({ msg, style }) => {
  return (
    <div className={styles.warningText} style={style}>
      <Icon iconName="varning" />
      {msg}
    </div>
  );
};

export default WarningMsg;
