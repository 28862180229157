import React from 'react';
import Icon from '../../misc/Icon';
import styles from './styles/ToggleButton.module.scss';

interface ToggleButtonProps {
  label: string;
  iconName: string;
  isSelected: boolean;
  onClick: () => void;
  selectedColor?: string;
  defaultColor?: string;
  minHeight?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  label,
  iconName,
  isSelected,
  onClick,
  selectedColor = '#eb5f62', // Default to optimal-red
  defaultColor = '#000',
  minHeight = '54px',
}) => {
  return (
    <div
      className={`${styles.toggleButton} ${isSelected ? styles.selected : ''}`}
      onClick={onClick}
      style={{
        backgroundColor: isSelected ? selectedColor : undefined,
        color: isSelected ? '#fff' : undefined,
        minHeight,
      }}
      title={label}
    >
      <Icon
        iconName={iconName}
        color={isSelected ? '#fff' : defaultColor}
        hoverColor={selectedColor}
        disableHover={true}
      />
      <span>{label}</span>
    </div>
  );
};

export default ToggleButton;
