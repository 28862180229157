// src/components/map/MapAreaList.tsx

import React, { useContext, useEffect, useRef, useState } from 'react';
import { optimalAPI } from '../../../api';
import { GlobalContext } from '../../../GlobalContext';
import { GeoCountPayload, GeoShape, Shape } from '../../../models';
import { optimalUtil } from '../../../util';
import NumberFormatter from '../../interface/global/NumberFormatter';
import TextInput from '../../interface/global/TextInput';
import Icon from '../../misc/Icon';
import {
  CheckIcon,
  DrawPolygonIcon,
  MagnifierIcon,
  PencilIcon,
  TrashAltIcon,
} from '../../misc/icons';
import Spinner from '../../misc/Spinner';
import styles from '../styles/Geo.module.scss';

interface MapAreaListProps {
  shapes: Shape[];
  editShape: Shape | null;
  onPanToShape: (shape: Shape) => void;
  removeShape: (uuid: string) => void;
  storeShape: (shape: Shape) => void;
  setEditShape: (shape: Shape | null) => void;
}

const MapAreaList: React.FC<MapAreaListProps> = ({
  shapes,
  editShape,
  onPanToShape,
  removeShape,
  storeShape,
  setEditShape,
}) => {
  const context = useContext(GlobalContext)!;
  const { globalObject } = context;
  const refNo = globalObject.refNo;

  const [counts, setCounts] = useState<Map<string, number>>(new Map());
  // useRef to avoid circular update in effect hook below
  const countsRef = useRef(counts);
  const [currentSaveArea, setCurrentSaveArea] = useState<string | null>(null);

  useEffect(() => {
    if (shapes.length === 0) return;

    const fetchCounts = async () => {
      const newCounts = new Map(countsRef.current);

      await Promise.all(
        shapes.map(async (shape) => {
          if (!newCounts.has(shape.uuid)) {
            const geoShapes: GeoShape[] = optimalUtil.convertShapeToGeoShape([
              shape,
            ]);
            const geoCountPayload: GeoCountPayload = {
              geoShape: geoShapes[0],
              refNo: refNo,
            };

            try {
              const count = await optimalAPI.countForGeo(geoCountPayload);
              newCounts.set(shape.uuid, count);
            } catch (error) {
              console.error('Failed to fetch count for shape:', error);
            }
          }
        }),
      );
      console.log('Done area counting, shapes length', shapes.length);
      setCounts(newCounts);
    };

    fetchCounts();
  }, [shapes, refNo]);

  const handleDeleteShape = (uuid: string) => {
    removeShape(uuid);
    /*const newCounts = new Map(counts);
    newCounts.delete(uuid);
    setCounts(newCounts);*/
  };

  const handleStartSaveArea = (uuid: string) => {
    setCurrentSaveArea(uuid === currentSaveArea ? null : uuid);
  };

  const handleStoreShape = (uuid: string, name: string) => {
    const shape = shapes.find((shape) => shape.uuid === uuid);
    if (shape) {
      shape.name = name;
      storeShape(shape);
    }
  };

  const handleEditShape = (shape: Shape) => {
    if (editShape && editShape.uuid === shape.uuid) {
      setEditShape(null);
    } else {
      setEditShape(shape);
      onPanToShape(shape);
    }
  };

  return (
    <div className={styles.mapAreaList}>
      <h4>
        <span className={styles.iconContainer}>
          <DrawPolygonIcon />
        </span>
        Karturval
      </h4>
      <h4>Antal</h4>
      <ul>
        {shapes.map((shape, index) => {
          const count = counts.get(shape.uuid);

          return (
            <li key={shape.uuid} style={{ color: shape.color }}>
              <div className={styles.omrade}>
                {currentSaveArea === shape.uuid ? (
                  <TextInput
                    small={true}
                    updateEachStroke={false}
                    placeholder="Välj ett namn + Enter"
                    setText={(text) => {
                      handleStoreShape(shape.uuid, text);
                    }}
                    cancel={() => {
                      setCurrentSaveArea(null);
                    }}
                  />
                ) : (
                  shape.name +
                  ' - ' +
                  shape.uuid.substring(shape.uuid.length - 5, shape.uuid.length)
                )}
              </div>
              <div className={styles.count}>
                <span className={styles.nf}>
                  {count ||
                    (count === 0 && (
                      <NumberFormatter value={count ? count : 0} />
                    ))}
                  {count === undefined && <Spinner type="dots" />}
                </span>
              </div>
              <div
                onClick={() => handleEditShape(shape)}
                className={styles.iconContainer}
              >
                {editShape && shape.uuid === editShape.uuid && (
                  <span className={styles.greenIcon}>
                    <CheckIcon />
                  </span>
                )}
                {(!editShape ||
                  (editShape && shape.uuid !== editShape.uuid)) && (
                  <PencilIcon />
                )}
              </div>
              <div
                onClick={() => onPanToShape(shape)}
                className={styles.iconContainer}
              >
                <MagnifierIcon />
              </div>
              <div
                onClick={() => handleDeleteShape(shape.uuid)}
                className={styles.iconContainer}
              >
                <TrashAltIcon />
              </div>{' '}
              <div className={styles.iconContainer}>
                <Icon
                  iconName="starOutline"
                  color={currentSaveArea === shape.uuid ? '#eb5f62' : 'black'}
                  onClick={() => {
                    handleStartSaveArea(shape.uuid);
                  }}
                />
              </div>
            </li>
          );
        })}
        {shapes.length === 0 && <li>Inga karturval gjorda</li>}
      </ul>
    </div>
  );
};

export default MapAreaList;
