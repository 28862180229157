import React, { useState } from 'react';
import styles from './styles/EmailInput.module.scss';

interface EmailInputProps {
  setEmail: (email: string) => void;
  style?: {};
}

const EmailInput: React.FC<EmailInputProps> = ({ setEmail, style }) => {
  const [email, setLocalEmail] = useState<string>('');

  // Helper function to validate email format
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle adding the email
  const handleAddEmail = () => {
    if (isValidEmail(email)) {
      setEmail(email);
      setLocalEmail(''); // Clear the input
    }
  };

  // Handle key press in input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddEmail();
    }
  };

  return (
    <div className={styles.container} style={style}>
      <input
        type="text"
        value={email}
        onChange={(e) => setLocalEmail(e.target.value)}
        onKeyDown={handleKeyDown} // Add email on pressing Enter
        placeholder="Mata in e-post"
        className={styles.input}
      />
      <span
        className={styles.icon}
        style={{
          color: isValidEmail(email) ? 'black' : 'lightgray',
          cursor: isValidEmail(email) ? 'pointer' : 'not-allowed',
        }}
        onClick={handleAddEmail}
      >
        +
      </span>
    </div>
  );
};

export default EmailInput;
