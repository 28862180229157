import React, { useEffect, useRef, useState } from 'react';
import { optimalAPI } from '../../../api';
import { locationCacheService } from '../../../cache/LocationCacheService';
import { SearchResult } from '../../../models';
import { optimalUtil } from '../../../util';
import styles from './styles/PickFromSearch.module.scss';

interface PickFromSearchProps {
  searchIndex: string;
  searchLabel?: string;
  addResult: (result: SearchResult) => void;
}

const PickFromSearch: React.FC<PickFromSearchProps> = ({
  searchIndex,
  searchLabel,
  addResult,
}) => {
  const [searchString, setSearchString] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (searchString.length > 2) {
      const doSearch = async () => {
        setSearching(true);
        try {
          const result = await optimalAPI.searchOptimal(
            searchIndex,
            searchString,
          );
          console.log(result);
          setSearchResult(result);
        } catch (error) {
          console.error('Search failed:', error);
        } finally {
          setSearching(false);
        }
      };

      const delayDebounceFn = setTimeout(() => {
        doSearch();
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchString, searchIndex]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchResult]);

  useEffect(() => {
    if (itemRefs.current[highlightedIndex]) {
      console.log('scroll into view');
      itemRefs.current[highlightedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  const handlePick = (pick: SearchResult) => {
    addResult(pick);
    if (pick.data && pick.type) {
      locationCacheService.setCachedLocation(
        `${optimalUtil.getLocationTypeCode(pick.type)}:${pick.data}`,
        pick.search,
      );
    }
    setSearchString('');
    setSearchResult([]);
    setHighlightedIndex(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prev) =>
        prev < searchResult.length - 1 ? prev + 1 : prev,
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      handlePick(searchResult[highlightedIndex]);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setSearchResult([]); // Close the dropdown
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setSearchResult([]); // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.searchComponent} ref={containerRef}>
      <input
        type="text"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder={`Sök ${searchLabel ? searchLabel : searchIndex}...`}
        disabled={searching}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        className={styles.searchInputText}
      />
      {searchString && searchResult.length > 0 && (
        <ul className={styles.searchResults}>
          {searchResult.map((result, index) => (
            <li
              key={index}
              onClick={() => handlePick(result)}
              className={`${styles.searchResultItem} ${
                index === highlightedIndex ? styles.selectedItem : ''
              }`}
              ref={(el) => (itemRefs.current[index] = el)}
            >
              {result.search} {result.type ? ` - ${result.type}` : ''}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PickFromSearch;
