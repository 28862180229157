import React from 'react';
import { UsersIcon } from '../misc/icons';
import ConsumptionPower from './ConsumptionPower';
import HouseholdComposition from './HouseholdComposition';
import styles from './styles/CriteriaGroup.module.scss';

interface HouseholdProps {
  active: boolean;
  householdComposition: ('E' | 'F' | 'X')[];
  consumptionPower: ('K1' | 'K2' | 'K3' | 'K4' | 'K5')[];
  updateSelectionCriteria: (path: string[], value: any) => void;
}

const HousholdCriteriaGroup: React.FC<HouseholdProps> = ({
  active,
  householdComposition,
  consumptionPower,
  updateSelectionCriteria,
}) => {
  const setConsumptionPower = (items: ('K1' | 'K2' | 'K3' | 'K4' | 'K5')[]) => {
    updateSelectionCriteria(['criteria', 'consumptionPower'], items);
  };
  const setHouseholdComposition = (items: ('E' | 'F' | 'X')[]) => {
    updateSelectionCriteria(['criteria', 'householdComposition'], items);
  };
  return (
    <>
      {active && (
        <div className={`${styles.criterionArea}`}>
          <h3>
            <UsersIcon />
            &nbsp;Hushåll:
          </h3>
          <div className={`${styles.CriteriaGroup} ${styles.subBox}`}>
            <HouseholdComposition
              active={active}
              householdComposition={householdComposition}
              setHouseholdComposition={setHouseholdComposition}
            />
            <ConsumptionPower
              consumptionPower={consumptionPower ?? []}
              active={true}
              setConsumptionPower={setConsumptionPower}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HousholdCriteriaGroup;
