import React, { useEffect, useState } from 'react';
import { getExclusions } from '../../api';
import { Exclusion } from '../../models';
import EditeableExclusions from './EditableExclusions';
import styles from './styles/Delivery.module.scss';

interface ExclusionSettingsProps {
  refNo: string;
}

const ExclusionSettings: React.FC<ExclusionSettingsProps> = ({ refNo }) => {
  const [exclusions, setExclusions] = useState<Exclusion[]>([]);

  useEffect(() => {
    const fetchExclusions = async () => {
      try {
        const exclusions = await getExclusions(refNo);
        setExclusions(exclusions);
        console.log('Exclusions loaded successfully', exclusions);
      } catch (error) {
        console.error('Failed to load reference copies:', error);
      }
    };

    fetchExclusions();
  }, [refNo]);

  return (
    <div className={`${styles.criterionArea}`}>
      <h3>Exklusioner:</h3>

      <EditeableExclusions initialRows={exclusions} refNo={refNo} />

      {/*<TextInput updateEachStroke={false} label="Ålder" setText={setAgeStr} />
      <StringItemList items={age} updateItems={setAge} unit="år" />*/}
      {/*<RangeSlider min={0} max={100} start={[20, 50]} onSlide={handleSlide} /> */}
    </div>
  );
};

export default ExclusionSettings;
