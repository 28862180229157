import React from 'react';
import { Units } from '../../conf/constants';
import { validateAge } from '../../validation';
import NumberSpan from '../interface/global/NumberSpan';
import StringItemList from '../interface/global/StringItemList';
import YearMonthSpan from '../interface/global/YearMonthSpan';
import styles from './styles/CriteriaGroup.module.scss';

interface AgeProps {
  age: string[];
  active: boolean;
  setAge: (items: string[]) => void;
  birthMonth: string[];
  setBirthMonth: (items: string[]) => void;
  birthYearAndMonth: string[];
  setBirthYearAndMonth: (items: string[]) => void;
}

const AgeAndBirthDate: React.FC<AgeProps> = ({
  age,
  active,
  setAge,
  birthYearAndMonth,
  setBirthYearAndMonth,
}) => {
  const setAgeStr = (ageStr: string) => {
    if (validateAge(ageStr)) {
      setAge([...age, ageStr]);
    }
  };

  const handleYearMonthSpanAdd = (span: string) => {
    setBirthYearAndMonth([...birthYearAndMonth, span]);
  };
  return (
    <>
      {active && (
        <div className={`${styles.CriteriaGroup} ${styles.subBox}`}>
          <h3>Åldersurval</h3>
          <NumberSpan
            setNumberSpan={setAgeStr}
            unit={Units.AGE}
            numberSpan={age}
            addMode={true}
          />
          <StringItemList items={age} updateItems={setAge} unit="år" />
          {/*<RangeSlider min={0} max={100} start={[20, 50]} onSlide={handleSlide} /> */}

          <h3>Födelseår och månad</h3>
          <YearMonthSpan setYearMonthSpan={handleYearMonthSpanAdd} />
          <StringItemList
            items={birthYearAndMonth}
            updateItems={setBirthYearAndMonth}
            itemIcon="cake"
          />
        </div>
      )}
    </>
  );
};

export default AgeAndBirthDate;
