import React, { useEffect, useState } from 'react';
import { optimalAPI } from '../../api';
import { SearchResult } from '../../models';
import PickFromSearch from '../interface/global/PickFromSearch';
import StringItemList from '../interface/global/StringItemList';
import styles from './styles/Housing.module.scss';

interface BrfProps {
  brfOrgNo: string[];
  setBrfOrgNo: (items: string[]) => void;
}

const Brf: React.FC<BrfProps> = ({ brfOrgNo, setBrfOrgNo }) => {
  const [brfMap, setBrfMap] = useState<{ [key: string]: string }>({});
  const [brfNames, setBrfNames] = useState<string[]>([]); // State to store the fetched BRF names

  const handleSetBrf = (result: SearchResult) => {
    if (result.data && !brfOrgNo.includes(result.data)) {
      setBrfMap((prevMap) => ({
        ...prevMap,
        [result.data as string]: result.search,
      }));
      setBrfOrgNo([...brfOrgNo, result.data]);
    }
  };

  useEffect(() => {
    const getBrfName = async (orgnr: string) => {
      if (brfMap[orgnr]) {
        return brfMap[orgnr];
      }
      try {
        const result = await optimalAPI.searchBRFOrgnr(orgnr);
        const fetchedName = result && result.search ? result.search : orgnr;
        setBrfMap((prevMap) => ({
          ...prevMap,
          [orgnr]: fetchedName,
        }));
        return fetchedName;
      } catch (error) {
        console.error('Can not find BRF');
        return 'Okänd BRF';
      }
    };

    const fetchBrfNames = async () => {
      const names = await Promise.all(
        brfOrgNo.map((orgnr) => getBrfName(orgnr)),
      );
      setBrfNames(names);
    };

    fetchBrfNames();
  }, [brfOrgNo, brfMap]);

  return (
    <div className={styles.brfWrap}>
      <div className={styles.criteriaLabel}>
        Lägg till Bostadsrättsförening:
      </div>
      <PickFromSearch searchIndex="brf" addResult={handleSetBrf} />
      <StringItemList
        items={brfOrgNo} // Passing the org numbers
        labels={brfNames} // Passing the resolved names
        itemIcon="building"
        style={{ marginTop: '15px' }}
        updateItems={setBrfOrgNo} // Update the org numbers directly
      />
    </div>
  );
};

export default Brf;
