import React, { useEffect, useState } from 'react';
import TelemarketingInstructions from '../../conf/TelemarketingInstructions';
import { Assignment, PhoneDeliveryDetails } from '../../models';
import styles from '../criteria/styles/CriteriaGroup.module.scss';
import EmailInput from '../interface/global/EmailInput';
import InfoIcon from '../interface/global/InfoIcon';
import StringItemList from '../interface/global/StringItemList';
import ToggleButton from '../interface/global/ToggleButton';
import WarningMsg from '../interface/global/WarningMsg';
import Icon from '../misc/Icon';

interface DeliverySettingsProps {
  assignment: Assignment;
  setAssignment: (assignment: Assignment) => void;
}

const DeliverySettings: React.FC<DeliverySettingsProps> = ({
  assignment,
  setAssignment,
}) => {
  const [phoneDelivery, setPhoneDelivery] = useState<PhoneDeliveryDetails>({
    emails: assignment.phoneDelivery?.emails ?? [],
    tmService: assignment.phoneDelivery?.tmService ?? false,
    tmServiceInstructions:
      assignment.phoneDelivery?.tmServiceInstructions ?? '',
  });
  const [telemarketingText, setTelemarketingText] = useState<string>('');
  const [showPhoneDelivery, setShowPhoneDelivery] = useState<boolean>(false);
  const [isDetailSaved, setIsDetailSaved] = useState<boolean>(
    assignment.phoneSelection,
  );

  const [warningMsg1, setWarningMsg1] = useState<string | null>(null);
  const [warningMsg2, setWarningMsg2] = useState<string | null>(null);

  useEffect(() => {
    if (phoneDelivery.emails.length === 0) {
      setWarningMsg2('Ingen/inga mottagare av ringlistan tillagd.');
    } else {
      setWarningMsg2(null);
    }
  }, [phoneDelivery.emails.length]);

  // Address Button Logic
  const handleAddressToggle = () => {
    if (!assignment.phoneSelection) {
      setWarningMsg1(
        'Det går inte att välja bort Adressurval om inte Telefonurval är valt.',
      );
      setTimeout(() => {
        setWarningMsg1(null);
      }, 7000);
      return;
    }
    setAssignment({
      ...assignment,
      addressSelection: !assignment.addressSelection,
    });
  };

  // Phone Button Logic
  const handlePhoneToggle = () => {
    setShowPhoneDelivery(!showPhoneDelivery); // Always show detail view
  };

  // Save Logic
  const handlePhoneSave = () => {
    if (phoneDelivery.tmService) {
      if (!telemarketingText.trim()) {
        alert('Vänligen fyll i instruktioner för telemarketing.');
        return;
      }
    } else if (!phoneDelivery.tmService && phoneDelivery.emails.length === 0) {
      return;
    }

    phoneDelivery.tmServiceInstructions = telemarketingText;
    // Save logic
    setAssignment({
      ...assignment,
      phoneSelection: true,
      phoneDelivery: phoneDelivery,
    });
    setShowPhoneDelivery(false);
    setIsDetailSaved(true);
  };

  // Cancel Logic
  const handlePhoneCancel = () => {
    setShowPhoneDelivery(false);
  };

  const handleRemovePhone = () => {
    if (assignment.addressSelection) {
      setAssignment({ ...assignment, phoneSelection: false });
      setShowPhoneDelivery(false);
    }
  };

  return (
    <div className={`${styles.criterionArea}`}>
      <h3>Typ av leverans</h3>
      <span style={{ fontSize: '15px' }}>
        Välj Adressurval och/eller Telefonurval:
      </span>
      <div
        className={`${styles.iconButtonMenu} ${showPhoneDelivery ? styles.dblMarginBottom : styles.marginBottom}`}
        style={{ alignItems: 'center' }}
      >
        <ToggleButton
          iconName="address"
          label="Adresser"
          isSelected={assignment.addressSelection}
          onClick={handleAddressToggle}
        />
        <span className={showPhoneDelivery ? styles.glow : ''}>
          <ToggleButton
            iconName="phone"
            label="Telefonnummer"
            isSelected={assignment.phoneSelection}
            onClick={handlePhoneToggle}
          />
        </span>
      </div>
      {warningMsg1 && <WarningMsg msg={warningMsg1} />}

      {showPhoneDelivery && (
        <div className={styles.phoneDeliverySection}>
          <h3 className={styles.noMarginBottom}>Skapa telefonurval</h3>
          <div style={{ fontSize: '15px', marginTop: '15px' }}>
            För att skapa Telefonurval, välj ringlista eller ringtjänst:
          </div>
          <div className={styles.iconButtonMenu}>
            <ToggleButton
              iconName="ringlista"
              label="Ringlista"
              isSelected={!phoneDelivery.tmService}
              onClick={() =>
                setPhoneDelivery({
                  ...phoneDelivery,
                  tmService: !phoneDelivery.tmService,
                })
              }
            />
            <ToggleButton
              iconName="callservice"
              label="Optimal ringtjänst"
              isSelected={phoneDelivery.tmService}
              onClick={() =>
                setPhoneDelivery({
                  ...phoneDelivery,
                  tmService: !phoneDelivery.tmService,
                })
              }
            />
          </div>
          {phoneDelivery.tmService && (
            <>
              <div
                className={styles.marginTop}
                style={{ display: 'flex', gap: '5px' }}
              >
                <strong>Instruktioner och önskemål för telemarketing</strong>
                <InfoIcon>
                  <TelemarketingInstructions />
                </InfoIcon>
              </div>
              <textarea
                value={telemarketingText}
                onChange={(e) => setTelemarketingText(e.target.value)}
                rows={7}
                cols={60}
                placeholder="Telemarketing instruktioner..."
                style={{ marginTop: '3px' }}
              />
            </>
          )}
          {!phoneDelivery.tmService && (
            <div className={styles.marginTop}>
              <strong>E-postadress(er) för leverans av ringlista</strong>
              <EmailInput
                setEmail={(email) =>
                  setPhoneDelivery({
                    ...phoneDelivery,
                    emails: [...phoneDelivery.emails, email],
                  })
                }
                style={{ marginBottom: '15px' }}
              />
              {warningMsg2 && <WarningMsg msg={warningMsg2} />}
              {phoneDelivery.emails.length > 0 && (
                <StringItemList
                  items={phoneDelivery.emails}
                  updateItems={(emails) =>
                    setPhoneDelivery({ ...phoneDelivery, emails })
                  }
                />
              )}
            </div>
          )}
          <div className={styles.buttonRow}>
            {assignment.phoneSelection && assignment.addressSelection && (
              <span
                onClick={handleRemovePhone}
                className={`${styles.saveButton} ${styles.gray}`}
              >
                <Icon iconName="avbryt" disableHover={true} color="#fff" /> Ta
                bort
              </span>
            )}
            <span
              onClick={handlePhoneCancel}
              className={`${styles.saveButton} ${styles.gray}`}
            >
              <Icon iconName="avbryt" disableHover={true} color="#fff" /> Avbryt
            </span>
            <span
              onClick={handlePhoneSave}
              className={`${styles.saveButton} ${(phoneDelivery.tmService && telemarketingText.length === 0) || (!phoneDelivery.tmService && phoneDelivery.emails.length === 0) ? styles.gray : ''}`}
            >
              <Icon iconName="spara" disableHover={true} color="#fff" /> Skapa
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeliverySettings;
