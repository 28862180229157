export interface Coordinates {
  lat: number;
  lng: number;
}

/* Geo and google maps component internal use */
export interface Shape {
  uuid: string;
  type: 'polygon' | 'circle';
  path?: Coordinates[];
  center?: Coordinates;
  radius?: number;
  color?: string;
  name?: string;
}

export interface GeoCoordinates {
  lat: number;
  lon: number;
}

export interface GeoCircle {
  lat: number;
  lon: number;
  radius: number;
}

export interface GeoShape {
  polygon?: GeoCoordinates[];
  circle?: GeoCircle;
}

export interface MapArea {
  name: string;
  shape: GeoShape;
}

export interface MapSettings {
  zoom?: number;
  center?: GeoCoordinates;
  mapAreas?: MapArea[];
}

export interface GeoCountPayload {
  refNo: string;
  geoShape: GeoShape;
}

export interface AddressCountPayload {
  refNo: string;
  streetAddress: StreetAddress;
}

export interface CriterionCountPayload {
  refNo: string;
  criterion: string;
  value: string;
}

export interface Assignment {
  user: string;
  customerNumber: string;
  name: string;
  addressSelection: boolean;
  phoneSelection: boolean;
  phoneDelivery?: PhoneDeliveryDetails;
  referenceCopies?: ReferenceCopy[];
  exclusions?: Exclusion[];
}

export interface Criteria {
  age?: string[]; // "50", "20-35"
  birthYearAndMonth?: string[]; // "199503"
  birthMonth?: string[]; // "01", "02", "03" ... "12"
  gender?: ('M' | 'K')[];
  county?: string[]; // Länskoder "01" för Sthlm, "18" för Örebro
  excludingCounty?: string[];
  municipality?: string[]; // Kommunkod, "0180" for Stockholm
  excludingMunicipality?: string[];
  aRegion?: string[]; // Arbetsmarknads-region "01" - "70"
  // Homogena regioner, läs: https://www.scb.se/contentassets/5f7f821206e24fc88501f4b6f5d1c559/mis-2003-1.pdf
  hRegion?: ('H1' | 'H3' | 'H4' | 'H5' | 'H6' | 'H8' | 'H9')[];
  zip?: string[];
  excludingZip?: string[];
  city?: string[]; // Postort
  excludingCity?: string[];
  electoralDistrict?: string[]; // Valdistrikt
  geo?: GeoShape[];
  addressType?: ('F' | 'S')[]; // Folkbokföringsadr / Särskild postadr
  streetAddress?: StreetAddress[];
  housingType?: ('V' | 'B' | 'H' | 'X')[];
  housingArea?: string[];
  housingValue?: string[];
  brfOrgNo?: string[];
  roomCount?: ('1' | '2' | '3' | '4' | '5' | '6-30')[];
  householdComposition?: ('E' | 'F' | 'X')[]; // E=One per household, F=Family, X=unknown
  consumptionPower?: ('K1' | 'K2' | 'K3' | 'K4' | 'K5')[]; // K1 fattig, K5 rik
  moveDate?: string[]; // Last move: "20200117", "20120501-20130501"
  countyBeforeLastMove?: string[]; // Regionskod
  excludingCountyBeforeLastMove?: string[]; // Exkludera regionskod
  municipalityBeforeLastMove?: string[]; // Kommunkod
  excludingMunicipalityBeforeLastMove?: string[]; // Exkludera kommunkod
  cityBeforeLastMove?: string[]; // Postort
  excludingCityBeforeLastMove?: string[]; // Exkludera postort
  constructionYear?: string[];
  acreage?: string[];
}

export interface StreetAddress {
  streetName: string;
  streetNumber?: string[];
  streetNumberParity?: 'ODD' | 'EVEN';
  zip?: string;
  city?: string;
}

export interface Selection {
  refNo: string;
  usage: 'DM' | 'TM' | 'DMTM';
  duplicateElimination: 'INDIVIDUAL' | 'HOUSEHOLD';
  householdPrio: 'RANDOM' | 'OLDEST' | 'YOUNGEST' | 'MALE' | 'FEMALE';
  blocks?: string[];
  limit: number;
  proximity?: GeoCoordinates;
  criteria?: Criteria;
}

export interface SelectionCount {
  version: string;
  refNo: string;
  count: number;
  grossCount: number;
  proximityRadius?: number;
}

export interface ProximityMarkerSettings {
  type: 'PROXIMITY' | 'SELECTION';
  size: number;
  done: boolean;
}

export interface MarkerData {
  coordinates: Coordinates;
  addressStr: string | null;
  proximityRadius?: number;
  limit: number;
  type: 'PROXIMITY' | 'SELECTION';
}

export interface PhoneDeliveryDetails {
  emails: string[];
  tmService: boolean;
  tmServiceInstructions: string;
}

export class ReferenceCopy {
  firstName: string;
  lastName: string;
  careOf: string;
  address: string;
  zip: string;
  city: string;
  phone: string;
  count: number;
  _id?: string;
  id?: string; // Id for data table

  // Constructor with parameters
  constructor(
    firstName: string = '',
    lastName: string = '',
    careOf: string = '',
    address: string = '',
    zip: string = '',
    city: string = '',
    phone: string = '',
    count: number = 1,
    _id?: string,
    id?: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.careOf = careOf;
    this.address = address;
    this.zip = zip;
    this.city = city;
    this.phone = phone;
    this.count = count;
    this._id = _id;
    this.id = id;
  }
}

export class Exclusion {
  _id?: string;
  id?: string; // Id for data table
  personalNumber: string;
  constructor(personalNumber: string = '', _id?: string, id?: string) {
    this.personalNumber = personalNumber;
    this._id = _id;
    this.id = id;
  }
}

export const getDefaultSelection = (refNo: string): Selection => {
  return {
    refNo: refNo,
    usage: 'DM',
    duplicateElimination: 'HOUSEHOLD',
    householdPrio: 'RANDOM',
    limit: 1000,
    proximity: {} as GeoCoordinates,
    criteria: {} as Criteria,
  };
};

export interface SearchResult {
  search: string;
  data?: string;
  type?: string;
}

// Type guard function
export function isKeyOfCriteria(key: any): key is keyof Criteria {
  return (
    key in
    {
      age: true,
      birthYearAndMonth: true,
      birthMonth: true,
      gender: true,
      county: true,
      municipality: true,
      aRegion: true,
      hRegion: true,
      zip: true,
      city: true,
      electoralDistrict: true,
      geo: true,
      addressType: true,
      housingType: true,
      householdComposition: true,
      consumptionPower: true,
      moveDate: true,
      countyBeforeLastMove: true,
      municipalityBeforeLastMove: true,
      zipBeforeLastMove: true,
      cityBeforeLastMove: true,
    }
  );
}
