import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import styles from './styles/InfoIcon.module.scss';

interface InfoIconProps {
  children: React.ReactNode; // Content for the popup
}

const InfoIcon: React.FC<InfoIconProps> = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  // Toggle the popup visibility
  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  // Close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.infoIconContainer}>
      <span className={styles.icon} onClick={togglePopup}>
        <Icon iconName="info" color="#eb5f62" hoverColor="#f08a8c" />
      </span>
      {showPopup && (
        <div className={styles.popup}>
          <button className={styles.closeButton} onClick={closePopup}>
            ×
          </button>
          <div className={styles.popupContent}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default InfoIcon;
