import React, { useContext, useEffect, useState } from 'react';
import { LoadingState } from '../../conf/constants';
import { GlobalContext } from '../../GlobalContext';
import Spinner from '../misc/Spinner';
import NumberFormatter from './global/NumberFormatter';
import styles from './styles/UrvalAdressTelefon.module.scss';

interface UrvalAdressTelefonProps {
  antalCount: number;
  antalGross: number;
  antalPhoneCount: number;
  antalPhoneGross: number;
}

const UrvalAdressTelefon: React.FC<UrvalAdressTelefonProps> = ({
  antalCount,
  antalGross,
}) => {
  const context = useContext(GlobalContext)!;
  const { globalObject, setGlobalObject } = context;
  const [state, setState] = useState<LoadingState>(LoadingState.UNSET);

  useEffect(() => {
    setState(globalObject.loadingState);
  }, [globalObject.loadingState]);
  return (
    <div className={styles.countBox}>
      {(state === LoadingState.UNSET || state === LoadingState.DONE) && (
        <div style={{ margin: '10px', height: '18px' }}>
          Antal:&nbsp;
          <NumberFormatter value={antalCount} />
          {antalGross > antalCount && (
            <span className={styles.tonedDown}>
              &nbsp;(
              <NumberFormatter value={antalGross} />)
            </span>
          )}
        </div>
      )}
      {state === LoadingState.LOADING && (
        <div style={{ margin: '10px', height: '18px' }}>
          <Spinner type="dots" />
        </div>
      )}
    </div>
  );
};

export default UrvalAdressTelefon;
