// GlobalContext.tsx
import React, { createContext, ReactNode, useState } from 'react';
import { LoadingState } from './conf/constants';

interface GlobalObject {
  refNo: string;
  userId: string;
  loadingState: LoadingState;
}

interface GlobalContextType {
  globalObject: GlobalObject;
  setGlobalObject: React.Dispatch<React.SetStateAction<GlobalObject>>;
}

export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined,
);

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [globalObject, setGlobalObject] = useState<GlobalObject>({
    refNo: 'value1',
    userId: 'value2',
    loadingState: LoadingState.UNSET,
  });

  return (
    <GlobalContext.Provider value={{ globalObject, setGlobalObject }}>
      {children}
    </GlobalContext.Provider>
  );
};
