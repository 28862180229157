import React, { useEffect, useState } from 'react';
import { getReferenceCopies } from '../../api';
import { ReferenceCopy } from '../../models';
import EditableDataGrid from './EditableDataGrid';
import styles from './styles/Delivery.module.scss';

interface RefExSettingsaProps {
  refNo: string;
}

const RefExSettings: React.FC<RefExSettingsaProps> = ({ refNo }) => {
  const [referenceCopies, setReferenceCopies] = useState<ReferenceCopy[]>([]);

  useEffect(() => {
    const fetchReferenceCopies = async () => {
      try {
        const copies = await getReferenceCopies(refNo);
        setReferenceCopies(copies);
        console.log('Reference copies loaded successfully', copies);
      } catch (error) {
        console.error('Failed to load reference copies:', error);
      }
    };

    fetchReferenceCopies();
  }, [refNo]);

  return (
    <div className={`${styles.criterionArea}`}>
      <h3>Referensexemplar:</h3>

      {referenceCopies.length > 0 ? (
        <>
          <EditableDataGrid initialRows={referenceCopies} refNo={refNo} />
        </>
      ) : (
        <p>Inga referensexemplar tillagda</p>
      )}

      {/*<TextInput updateEachStroke={false} label="Ålder" setText={setAgeStr} />
      <StringItemList items={age} updateItems={setAge} unit="år" />*/}
      {/*<RangeSlider min={0} max={100} start={[20, 50]} onSlide={handleSlide} /> */}
    </div>
  );
};

export default RefExSettings;
