import React from 'react';
import ToggleButton from '../interface/global/ToggleButton';
import styles from './styles/CriteriaGroup.module.scss';

interface GenderProps {
  gender: ('M' | 'K')[];
  active: boolean;
  setGender: (items: ('M' | 'K')[]) => void;
}

const Gender: React.FC<GenderProps> = ({ gender, active, setGender }) => {
  const handleSelect = (value: 'M' | 'K') => {
    if (gender.includes(value)) {
      setGender(gender.filter((item) => item !== value));
    } else {
      setGender([...gender, value]);
    }
  };

  return (
    <>
      {active && (
        <div className={`${styles.CriteriaGroup} ${styles.subBox}`}>
          <h3>Välj kön:</h3>
          <div className={styles.iconButtonMenu}>
            <ToggleButton
              label="Män"
              iconName="genderMale"
              isSelected={gender.includes('M')}
              onClick={() => handleSelect('M')}
            />
            <ToggleButton
              label="Kvinnor"
              iconName="genderFemale"
              isSelected={gender.includes('K')}
              onClick={() => handleSelect('K')}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Gender;
