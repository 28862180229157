import React, { useState } from 'react';
import styles from './styles/Span.module.scss';

interface YearMonthSpanProps {
  setYearMonthSpan: (yearMonthSpan: string) => void;
  onlyYear?: boolean; // New prop for year-only mode
}

const YearMonthSpan: React.FC<YearMonthSpanProps> = ({
  setYearMonthSpan,
  onlyYear = false,
}) => {
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [error, setError] = useState<string>(''); // Error message for validation feedback

  const isValidYearMonth = (value: string): boolean =>
    onlyYear
      ? /^\d{4}$/.test(value) // Validate only year (YYYY)
      : /^\d{6}$/.test(value) &&
        Number(value.slice(4)) >= 1 &&
        Number(value.slice(4)) <= 12; // Validate year and month (YYYYMM)

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFrom(value);
    setError(''); // Clear any existing error
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTo(value);
    setError(''); // Clear any existing error
  };

  const handleSetSpan = () => {
    // Validate individual inputs
    const fromValid = isValidYearMonth(from);
    const toValid = isValidYearMonth(to);

    if (!fromValid && !toValid) {
      setError(
        onlyYear
          ? 'Året måste vara på formatet ÅÅÅÅ.'
          : 'Födelsemånad måste vara på formatet ÅÅÅÅMM.',
      );
      return;
    }

    if (fromValid && toValid && from > to) {
      setError(
        `"Från" ${onlyYear ? 'året' : 'datumet'} måste vara tidigare eller lika med "Till" ${
          onlyYear ? 'året' : 'datumet'
        }.`,
      );
      return;
    }

    let newTo = to;
    if (!to) {
      const today = new Date();
      newTo = onlyYear
        ? `${today.getFullYear()}`
        : `${today.getFullYear()}${(today.getMonth() + 1)
            .toString()
            .padStart(2, '0')}`;
      setTo(newTo);
    }

    // Determine the span to set
    if (fromValid) {
      setYearMonthSpan(`${from}-${newTo}`);
    } else if (fromValid) {
      setYearMonthSpan(from);
    } else if (toValid) {
      setYearMonthSpan(to);
    }

    // Clear inputs and error
    setFrom('');
    setTo('');
    setError('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSetSpan(); // Trigger span addition on Enter
    }
  };

  return (
    <>
      <div className={styles.yearMonthSpanContainer}>
        <div>
          <label className={styles.label} htmlFor="fromDate">
            Från:
          </label>
          <input
            type="text"
            value={from}
            onChange={handleFromChange}
            maxLength={onlyYear ? 4 : 6}
            className={styles.numberInput}
            placeholder={onlyYear ? 'ÅÅÅÅ' : 'ÅÅÅÅMM'}
          />
        </div>
        <span className={styles.dash}>–</span>
        <div>
          <label className={styles.label} htmlFor="toDate">
            Till:
          </label>
          <input
            type="text"
            value={to}
            onChange={handleToChange}
            onKeyDown={handleKeyPress} // Add functionality for Enter key
            maxLength={onlyYear ? 4 : 6}
            className={styles.numberInput}
            placeholder={onlyYear ? 'ÅÅÅÅ' : 'ÅÅÅÅMM'}
          />
        </div>
        <button
          onClick={handleSetSpan}
          className={styles.addButton}
          title="Lägg till"
        >
          +
        </button>
      </div>
      <div className={styles.error}>{error}</div>
    </>
  );
};

export default YearMonthSpan;
