import React, { useState } from 'react';
import styles from './styles/TextInput.module.scss';

interface TextInputProps {
  label?: string;
  placeholder?: string;
  small?: boolean;
  updateEachStroke: boolean;
  cancel?: () => void;
  setText: (text: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  updateEachStroke,
  small,
  cancel,
  setText,
  onKeyDown,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (updateEachStroke) {
      setText(newValue);
    }
  };

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
  };

  const handleKeyStroke = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setText(inputValue);
      setInputValue('');
    }
    if (event.key === 'Escape') {
      handleCancel();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <div className={`${styles.TextInput}`}>
      {label && <label htmlFor="textInput">{label}</label>}
      <input
        type="text"
        id="textInput"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyStroke}
        onBlur={handleCancel}
        autoComplete="off"
        style={small ? { width: '210px', height: '25px' } : {}}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextInput;
