import React from 'react';
import { SearchResult } from '../../models';
import { optimalUtil } from '../../util';
import DateSpan from '../interface/global/DateSpan';
import Icon from '../misc/Icon';
import MoveLocationList from './MoveLocationList';
import styles from './styles/CriteriaGroup.module.scss';

interface MoveProps {
  active: boolean;
  moveDate: string[];
  excludingMoveDate: string[];
  countyBeforeLastMove: string[];
  excludingCountyBeforeLastMove: string[];
  municipalityBeforeLastMove: string[];
  excludingMunicipalityBeforeLastMove: string[];
  cityBeforeLastMove: string[];
  excludingCityBeforeLastMove: string[];
  updateSelectionCriteria: (path: string[], value: any) => void;
  transferSelectionCriteria: (
    sourcePath: string[],
    targetPath: string[],
    value: any,
  ) => void;
}

const MoveCriteriaGroup: React.FC<MoveProps> = ({
  active,
  moveDate,
  excludingMoveDate,
  countyBeforeLastMove,
  excludingCountyBeforeLastMove,
  municipalityBeforeLastMove,
  excludingMunicipalityBeforeLastMove,
  cityBeforeLastMove,
  excludingCityBeforeLastMove,
  updateSelectionCriteria,
  transferSelectionCriteria,
}) => {
  const setCounty = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      [
        'criteria',
        exclude ? 'excludingCountyBeforeLastMove' : 'countyBeforeLastMove',
      ],
      items,
    );
  };
  const setMunicipality = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      [
        'criteria',
        exclude
          ? 'excludingMunicipalityBeforeLastMove'
          : 'municipalityBeforeLastMove',
      ],
      items,
    );
  };
  const setCity = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      [
        'criteria',
        exclude ? 'excludingCityBeforeLastMove' : 'cityBeforeLastMove',
      ],
      items,
    );
  };

  const handleSetLocation = (location: SearchResult) => {
    // 'r' | 'k' | 'p'
    console.log('handle search location', location);
    if (location.data) {
      if (location.type === 'Region') {
        setCounty([...countyBeforeLastMove, location.data]);
      } else if (location.type === 'Kommun') {
        setMunicipality([...municipalityBeforeLastMove, location.data]);
      } else if (location.type === 'Postort') {
        setCity([...cityBeforeLastMove, location.data]);
      }
    }
  };

  const handleLocationExcludeToggle = (
    type: string,
    value: string,
    exclude: boolean,
  ) => {
    // if exclude === true: move from regular to excluding
    const sourceCriteria = `${optimalUtil.getLocationCriterion(type, !exclude)}BeforeLastMove`;
    const targetCriteria = `${optimalUtil.getLocationCriterion(type, exclude)}BeforeLastMove`;
    console.log(
      'Transfering',
      value,
      'from',
      sourceCriteria,
      'to',
      targetCriteria,
    );
    transferSelectionCriteria(
      ['criteria', sourceCriteria],
      ['criteria', targetCriteria],
      value,
    );
  };
  /*  countyBeforeLastMove,
  excludingCountyBeforeLastMove,
  municipalityBeforeLastMove,
  excludingMunicipalityBeforeLastMove,
  cityBeforeLastMove,
  excludingCityBeforeLastMove, */
  const handleRemoveLocation = (
    type: string,
    value: string,
    exclude: boolean,
  ) => {
    console.log('Remove', type, value, exclude);
    const criteria = `${optimalUtil.getLocationCriterion(type, exclude)}BeforeLastMove`;
    let values = getCriteriaValues(criteria);
    updateSelectionCriteria(
      ['criteria', criteria],
      getValuesRemoved(values, value),
    );
  };

  const getCriteriaValues = (criteria: string) => {
    if (criteria === 'countyBeforeLastMove') return countyBeforeLastMove;
    if (criteria === 'excludingCountyBeforeLastMove')
      return excludingCountyBeforeLastMove;
    if (criteria === 'municipalityBeforeLastMove')
      return municipalityBeforeLastMove;
    if (criteria === 'excludingMunicipalityBeforeLastMove')
      return excludingMunicipalityBeforeLastMove;
    if (criteria === 'cityBeforeLastMove') return cityBeforeLastMove;
    if (criteria === 'excludingCityBeforeLastMove')
      return excludingCityBeforeLastMove;
    throw Error('Unknown criteria.');
  };

  const getValuesRemoved = (values: string[], value: string): string[] => {
    // Filter out the value to be removed from the array
    return values.filter((item) => item !== value);
  };

  return (
    <>
      {active && (
        <>
          <div className={`${styles.criterionArea} ${styles.subBox}`}>
            <h3>
              <Icon iconName="move" />
              &nbsp;Flytturval
            </h3>
            <div className={styles.subBox}>
              <span className={styles.criteriaLabel}>
                Datumspann för flytt:
              </span>
              <DateSpan
                dateSpan={moveDate[0] ?? ''}
                setDateSpan={(item) =>
                  updateSelectionCriteria(['criteria', 'moveDate'], [item])
                }
              />
            </div>
            <div className={styles.subBox}>
              <span className={`${styles.criteriaLabel}`}>
                Välj flytt från ort:
              </span>
              <MoveLocationList
                countyBeforeLastMove={countyBeforeLastMove}
                excludingCountyBeforeLastMove={excludingCountyBeforeLastMove}
                municipalityBeforeLastMove={municipalityBeforeLastMove}
                excludingMunicipalityBeforeLastMove={
                  excludingMunicipalityBeforeLastMove
                }
                cityBeforeLastMove={cityBeforeLastMove}
                excludingCityBeforeLastMove={excludingCityBeforeLastMove}
                toggle={handleLocationExcludeToggle}
                remove={handleRemoveLocation}
                addResult={handleSetLocation}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MoveCriteriaGroup;
