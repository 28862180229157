import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ElephantIcon,
  HouseIcon,
  MailboxIcon,
  MapMarkerIcon,
  UserIcon,
  UsersIcon,
} from '../misc/icons';
import styles from './styles/UrvalsMeny.module.scss';

interface UrvalsMenyButtonProps {
  component: string;
  isActive: boolean;
  onClick: () => void;
  label: string;
}

const UrvalsMenyButton: React.FC<UrvalsMenyButtonProps> = ({
  component,
  isActive,
  onClick,
  label,
}) => {
  const color = isActive ? '#fff' : '#000';
  const iconMap: Record<string, React.ReactNode> = {
    housing: <HouseIcon />,
    person: <UserIcon />,
    location: <MailboxIcon />,
    household: <UsersIcon />,
    //household: <UsersIcon />,
    geo: <MapMarkerIcon />,
    move: <FontAwesomeIcon icon={faTruckLoading} onClick={onClick} />,
    elephant: <ElephantIcon />,
  };

  const icon = iconMap[component] || <ElephantIcon />; // Default to ElephantIcon if not found

  return (
    <button
      onClick={onClick}
      className={`${isActive ? styles.active : ''} ${styles.menuButton}`}
    >
      <span>{icon}</span>
      <span>{label}</span>
    </button>
  );
};

export default UrvalsMenyButton;
