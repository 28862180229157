import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import {
  faBirthdayCake,
  faBuilding,
  faChildren as faFamily,
  faFemale,
  faHeadset,
  faInfoCircle,
  faMale,
  faTruckLoading as faMove,
  faPhone,
  faStar as faStarSolid,
  faTableList,
  faTimesCircle,
  faUserAstronaut,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  BuildingIcon,
  CheckSquareIcon,
  GenderFemaleIcon,
  GenderMaleIcon,
  HouseIcon,
  IglooIcon,
  MailboxIcon,
  SaveIcon,
  SquareIcon,
  TimesCircleIcon,
  UserIcon,
  UsersIcon,
  WarningIcon,
} from './icons';

interface IconProps {
  iconName: string;
  color?: string;
  hoverColor?: string;
  className?: string;
  onClick?: () => void;
  disableHover?: boolean; // New prop to disable hover effects
}

// FontAwesome icons map
const iconMap: { [key: string]: IconDefinition } = {
  close: faTimesCircle,
  star: faStarSolid,
  starOutline: faStarRegular,
  building: faBuilding,
  move: faMove,
  family: faFamily,
  cake: faBirthdayCake,
  male: faMale,
  female: faFemale,
  unknown: faUserAstronaut,
  ringlista: faTableList,
  callservice: faHeadset,
  phone: faPhone,
  info: faInfoCircle,
};

// Legacy icons
const legacyIconMap: { [key: string]: React.FC } = {
  genderMale: GenderMaleIcon,
  genderFemale: GenderFemaleIcon,
  house: HouseIcon,
  hr: BuildingIcon,
  iglo: IglooIcon,
  checked: CheckSquareIcon,
  unchecked: SquareIcon,
  single: UserIcon,
  users: UsersIcon,
  address: MailboxIcon,
  varning: WarningIcon,
  spara: SaveIcon,
  avbryt: TimesCircleIcon,
};

const Icon: React.FC<IconProps> = ({
  iconName,
  color = 'black',
  hoverColor = '#eb5f62',
  className,
  onClick,
  disableHover = false, // Default to false
}) => {
  const fontAwesomeIcon = iconMap[iconName];
  const CustomIconComponent = legacyIconMap[iconName];
  const [isHovered, setIsHovered] = useState(false);

  // Handle hover only if hover effects are enabled
  const handleMouseEnter = () => {
    if (!disableHover) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!disableHover) {
      setIsHovered(false);
    }
  };

  // Render FontAwesome icon if found in iconMap
  if (fontAwesomeIcon) {
    return (
      <FontAwesomeIcon
        icon={fontAwesomeIcon}
        className={className}
        style={{
          color: isHovered && hoverColor ? hoverColor : color,
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  }

  // Render custom React component icon if found in customIconMap
  if (CustomIconComponent) {
    return (
      <div
        className={className}
        style={{
          color: isHovered && hoverColor ? hoverColor : color,
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomIconComponent />
      </div>
    );
  }

  // If icon not found, log a warning
  console.warn(
    `Icon "${iconName}" not found in the icon map or custom icon map.`,
  );
  return null;
};

export default Icon;
