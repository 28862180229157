import React from 'react';
import CheckboxLabel from '../interface/global/CheckboxLabel';
import styles from './styles/Housing.module.scss';

interface HousingValueProps {
  housingValue: string[];
  setHousingValue: (value: string[]) => void;
}

const HousingValue: React.FC<HousingValueProps> = ({
  housingValue,
  setHousingValue,
}) => {
  const handleChange = (value: string, checked: boolean) => {
    if (checked) {
      // Add to gender array if checked and not already included
      setHousingValue([...housingValue, value]);
    } else {
      // Remove from gender array if unchecked
      setHousingValue(housingValue.filter((item) => item !== value));
    }
  };

  return (
    <div className={styles.CriteriaGroup}>
      <div className={styles.criteriaLabel}>Välj bostadsvärde:</div>
      <div className={`${styles.menu} `}>
        <CheckboxLabel
          checked={housingValue.includes('0')}
          value="0"
          label="0-500 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('500')}
          value="500"
          label="500 000-1 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('1000')}
          value="1000"
          label="1 000 000-1 500 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('1500')}
          value="1500"
          label="1 500 000-2 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('2000')}
          value="2000"
          label="2 000 000-2 500 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('2500')}
          value="2500"
          label="2 500 000-3 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('3000')}
          value="3000"
          label="3 000 000-4 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('4000')}
          value="4000"
          label="4 000 000-6 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('6000')}
          value="6000"
          label="6 000 000-8 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('8000')}
          value="8000"
          label="8 000 000-10 000 000 SEK"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={housingValue.includes('10000')}
          value="10000"
          label="10 000 000+ SEK"
          setValue={handleChange}
        />
      </div>
    </div>
  );
};

export default HousingValue;
