export function validateAge(input: string): boolean {
  if (input.includes("-")) {
    // Handle age range
    const parts = input.split("-").map((part) => parseInt(part.trim(), 10));
    if (parts.length !== 2) return false;
    const [startAge, endAge] = parts;
    return startAge >= 17 && endAge <= 125 && startAge <= endAge;
  } else {
    // Handle single age case
    const age = parseInt(input.trim(), 10);
    return age >= 17 && age <= 125;
  }
}
