import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer,
  useGridApiRef,
} from '@mui/x-data-grid';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  addReferenceCopy,
  deleteReferenceCopy,
  editReferenceCopy,
} from '../../api';
import { ReferenceCopy } from '../../models';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    const refCopy = new ReferenceCopy();
    refCopy.id = id;
    console.log('Attempting to set new refCopy', refCopy);

    setRows((oldRows) => [...oldRows, refCopy]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'firstName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Lägg till referenskopia
      </Button>
    </GridToolbarContainer>
  );
}

interface EditableDataGridProps {
  initialRows: GridRowsProp;
  refNo: string;
}

export default function EditableDataGrid({
  initialRows,
  refNo,
}: EditableDataGridProps) {
  const apiRef = useGridApiRef();

  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //const apiRef = useGridApiRef();

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id: GridRowId) => {
    setRowModesModel((prevRowModesModel) => ({
      ...prevRowModesModel,
      [id]: { mode: GridRowModes.View }, // This triggers processRowUpdate automatically
    }));

    // Wait for the mode change to complete (optional)
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  const handleDeleteClick = async (deleteRow: any) => {
    /*console.log("Deleting row: ", deleteRow);

    if(!deleteRow._id) {
        setRows(rows.filter((row) => row.id !== deleteRow.id)); // Row hasn't yet been added to DB
    }
    else {
        const result = await deleteReferenceCopy(refNo, deleteRow as ReferenceCopy);
        console.log("Delete result:", result);
        setRows(rows.filter((row) => {
            console.log("Row to compare with:", row);
            console.log("Row that was deleted: ", deleteRow);
            console.log("Row id: ", row.id)
            console.log("Dewleted row id: ", deleteRow.id);
            return row._id !== deleteRow._id
        }));
    }*/
    if (deleteRow._id) {
      const result = await deleteReferenceCopy(
        refNo,
        deleteRow as ReferenceCopy,
      );
      if (deleteRow.id) {
        apiRef.current.updateRows([{ id: deleteRow.id, _action: 'delete' }]);
      } else {
        apiRef.current.updateRows([{ id: deleteRow._id, _action: 'delete' }]);
      }
    } else {
      apiRef.current.updateRows([{ id: deleteRow.id, _action: 'delete' }]); // Row hasn't yet been added to DB
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    /*const editedRow = rows.find((row) => row.id === id);
    if (editedRow && !editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }*/
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && !editedRow.isNew) {
      apiRef.current.updateRows([{ id: editedRow.id, _action: 'delete' }]);
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log('Processed row update!', newRow);

    newRow.rowError = undefined;

    // Validation for 'zip' field (Postcode)
    const zipRegex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
    if (!zipRegex.test(newRow.zip)) {
      if (!newRow.rowError) {
        newRow.rowError = {};
      }
      newRow.rowError.zip = true;
    }

    // Validation for 'phone' field using the provided regex
    const phoneRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (!phoneRegex.test(newRow.phone)) {
      if (!newRow.rowError) {
        newRow.rowError = {};
      }
      newRow.rowError.phone = true;
    }

    // Validation for count field.
    if (newRow.count === '' || newRow.count < 1) {
      if (!newRow.rowError) {
        newRow.rowError = {};
      }
      newRow.rowError.count = true;
    }

    let updatedRefCopy: ReferenceCopy;

    /*if(newRow.rowError) {
        if(newRow._id) {
            // Update existing row
            setRows((prevRows) =>
                prevRows.map((row) => (row._id === newRow._id ? newRow : row))
            );
        }
        else {
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === newRow.id ? newRow : row))
            );
        }
    }
    else {
        if(newRow._id) {
            // Update existing row
            updatedRefCopy = await editReferenceCopy(newRow as ReferenceCopy, refNo);
            setRows((prevRows) =>
                prevRows.map((row) => (row._id === newRow._id ? updatedRefCopy : row))
            );
        }
        else {
            updatedRefCopy = await addReferenceCopy(newRow as ReferenceCopy, refNo);
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === newRow.id ? updatedRefCopy : row))
            );
        }
        console.log("Rows", rows);

        return updatedRefCopy; 
    }*/
    if (newRow.rowError) {
      console.log('Row had error.');
      apiRef.current.updateRows([newRow]);
    } else {
      console.log('Row did not have error', newRow);

      if (newRow._id) {
        // Update existing row
        updatedRefCopy = await editReferenceCopy(
          newRow as ReferenceCopy,
          refNo,
        );
        if (newRow.id) {
          updatedRefCopy.id = newRow.id;
        }
        apiRef.current.updateRows([updatedRefCopy]);
      } else {
        updatedRefCopy = await addReferenceCopy(newRow as ReferenceCopy, refNo);
        updatedRefCopy.id = newRow.id;
        apiRef.current.updateRows([updatedRefCopy]);
      }

      console.log('Rows', rows);

      return updatedRefCopy;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 100,
      editable: true,
    },
    { field: 'lastName', headerName: 'Last Name', width: 100, editable: true },
    {
      field: 'careOf',
      headerName: 'Care Of (c/o)',
      width: 100,
      editable: true,
    },
    { field: 'address', headerName: 'Address', width: 200, editable: true },
    {
      field: 'zip',
      headerName: 'Postcode',
      width: 100,
      editable: true,
      cellClassName: (params) => {
        if (params.row.rowError && params.row.rowError.zip) {
          return 'error-cell'; // Apply error class if there's an error on the postcode
        }
        return '';
      },
    },
    { field: 'city', headerName: 'City', width: 100, editable: true },
    {
      field: 'phone',
      headerName: 'Telephone',
      width: 150,
      editable: true,
      cellClassName: (params) => {
        if (params.row.rowError && params.row.rowError.phone) {
          return 'error-cell'; // Apply error class if there's an error on the phone field
        }
        return '';
      },
    },
    {
      field: 'count',
      headerName: 'Count',
      width: 100,
      type: 'number',
      editable: true,
      cellClassName: (params) => {
        if (params.row.rowError && params.row.rowError.count) {
          return 'error-cell'; // Apply error class if there's an error on the postcode
        }
        return '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowId={(row) => (row.id ? row.id : row._id)}
        disableColumnMenu
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        apiRef={apiRef}
      />
    </Box>
  );
}
