import React, { useContext } from 'react';
import { Units } from '../../conf/constants';
import { GlobalContext } from '../../GlobalContext';
import { SearchResult, StreetAddress } from '../../models';
import { optimalUtil } from '../../util';
import NumberSpan from '../interface/global/NumberSpan';
import PickFromSearch from '../interface/global/PickFromSearch';
import { MailboxIcon } from '../misc/icons';
import LocationList from './LocationList';
import StreetAddresses from './StreetAddresses';
import styles from './styles/CriteriaGroup.module.scss';

interface LocationProps {
  active: boolean;
  googleLoaded: boolean;
  county: string[];
  excludingCounty: string[];
  municipality: string[];
  excludingMunicipality: string[];
  aRegion: string[];
  hRegion: string[];
  zip: string[];
  excludingZip: string[];
  city: string[];
  excludingCity: string[];
  electoralDistrict: string[];
  addressType: string[];
  streetAddress: StreetAddress[];
  updateSelectionCriteria: (path: string[], value: any) => void;
  transferSelectionCriteria: (
    sourcePath: string[],
    targetPath: string[],
    value: any,
  ) => void;
}

const LocationCriteriaGroup: React.FC<LocationProps> = ({
  active,
  googleLoaded,
  county,
  excludingCounty,
  municipality,
  excludingMunicipality,
  zip,
  excludingZip,
  city,
  excludingCity,
  streetAddress,
  updateSelectionCriteria,
  transferSelectionCriteria,
}) => {
  const setCity = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      ['criteria', exclude ? 'excludingCity' : 'city'],
      items,
    );
  };
  const setCounty = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      ['criteria', exclude ? 'excludingCounty' : 'county'],
      items,
    );
  };
  const setMunicipality = (items: string[], exclude?: boolean) => {
    updateSelectionCriteria(
      ['criteria', exclude ? 'excludingMunicipality' : 'municipality'],
      items,
    );
  };
  const setZip = (item: string, exclude?: boolean) => {
    updateSelectionCriteria(
      ['criteria', exclude ? 'excludingZip' : 'zip'],
      [...zip, item],
    );
  };
  const setStreetAddress = (items: StreetAddress[]) => {
    updateSelectionCriteria(['criteria', 'streetAddress'], items);
  };
  const { globalObject } = useContext(GlobalContext)!;

  const handleSetLocation = (location: SearchResult) => {
    // 'r' | 'k' | 'p'
    console.log('handle search location', location);
    if (location.data) {
      if (location.type === 'Region') {
        setCounty([...county, location.data]);
      } else if (location.type === 'Kommun') {
        setMunicipality([...municipality, location.data]);
      } else if (location.type === 'Postort') {
        setCity([...city, location.data]);
      }
    }
  };

  const handleLocationExcludeToggle = (
    type: string,
    value: string,
    exclude: boolean,
  ) => {
    // if exclude === true: move from regular to excluding
    const sourceCriteria = optimalUtil.getLocationCriterion(type, !exclude);
    const targetCriteria = optimalUtil.getLocationCriterion(type, exclude);
    /*console.log(
      'Transfering',
      value,
      'from',
      sourceCriteria,
      'to',
      targetCriteria,
    );*/
    transferSelectionCriteria(
      ['criteria', sourceCriteria],
      ['criteria', targetCriteria],
      value,
    );
  };

  const handleRemoveLocation = (
    type: string,
    value: string,
    exclude: boolean,
  ) => {
    console.log('Remove', type, value, exclude);
    const criteria = optimalUtil.getLocationCriterion(type, exclude);
    let values = getCriteriaValues(criteria);
    updateSelectionCriteria(
      ['criteria', criteria],
      getValuesRemoved(values, value),
    );
  };

  const getCriteriaValues = (criteria: string) => {
    if (criteria === 'county') return county;
    if (criteria === 'excludingCounty') return excludingCounty;
    if (criteria === 'municipality') return municipality;
    if (criteria === 'excludingMunicipality') return excludingMunicipality;
    if (criteria === 'city') return city;
    if (criteria === 'excludingCity') return excludingCity;
    if (criteria === 'zip') return zip;
    if (criteria === 'excludingZip') return excludingZip;
    throw Error('Unknown criteria.');
  };

  const getValuesRemoved = (values: string[], value: string): string[] => {
    // Filter out the value to be removed from the array
    return values.filter((item) => item !== value);
  };

  return (
    <>
      {active && (
        <div className={`${styles.criterionArea}`}>
          <h3>
            <MailboxIcon />
            &nbsp;Adressurval:
          </h3>
          <StreetAddresses
            active={true}
            refNo={globalObject.refNo}
            googleLoaded={googleLoaded}
            streetAddress={streetAddress}
            setStreetAddress={setStreetAddress}
          />
          <div className={styles.subBox}>
            <h3>Boende i område</h3>
            <div className={styles.row}>
              <div className={styles.redFrame}>
                <span className={styles.label}>
                  Boende inom kommun, region och postort
                </span>
                <PickFromSearch
                  searchIndex="locations"
                  addResult={handleSetLocation}
                  searchLabel="kommun, region, postort"
                />
              </div>
              <NumberSpan
                unit={Units.ZIP}
                setNumberSpan={setZip}
                addMode={true}
                labelFrom="Postnr från"
                labelTo="Postnr t.o.m"
                minSize={5}
                maxSize={5}
                width="80px"
              />
            </div>
            <LocationList
              county={county}
              excludingCounty={excludingCounty}
              municipality={municipality}
              excludingMunicipality={excludingMunicipality}
              city={city}
              excludingCity={excludingCity}
              zip={zip}
              excludingZip={excludingZip}
              toggle={handleLocationExcludeToggle}
              remove={handleRemoveLocation}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationCriteriaGroup;
