import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { getCount, updateCount } from '../../api';
import styles from './styles/Delivery.module.scss';

interface CountSettingProps {
  refNo: string;
}

const CountSettings: React.FC<CountSettingProps> = ({ refNo }) => {
  /*const [count, setCount] = useState<Number>(0);

    let initialCount = 0; 

        useEffect(() => {
            const fetchReferenceCopies = async () => {
            try {
                const fetchedCount = await getCount(refNo);
                setCount(fetchedCount);
                console.log('Count loaded successfully', fetchedCount);
            } catch (error) {
                console.error('Failed to load count:', error);
            }
            };

            fetchReferenceCopies();
        }, [refNo]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //const { name, value } = e.target;
        
    };
      
  
    return (
        <div className={`${styles.criterionArea}`}>
        <h3>
            Antal:
        </h3>

        <FormControl variant="standard">
            <TextField
            id="count"
            name="count"
            type="number"
            value={initialCount}
            onChange={handleChange}
            label="Count"
            InputLabelProps={{ shrink: true }}
            />
        </FormControl>

        
        </div>
    );*/

  const [count, setCount] = useState<Number>(0);
  const [loading, setLoading] = useState(true);

  // Fetch initial count when component mounts
  useEffect(() => {
    const fetchInitialCount = async () => {
      try {
        const initialCount = await getCount(refNo);
        setCount(initialCount);
        setLoading(false); // Disable loading once we have the data
      } catch (error) {
        console.error('Failed to fetch initial count', error);
      }
    };

    fetchInitialCount();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Handle input change and update count
  const handleChange = async (event: any) => {
    const newCount = event.target.value;
    console.log('Setting new count: ', newCount);

    try {
      let result = await updateCount(newCount, refNo);
      console.log('Resulttt: ', result);
      setCount(result ? result : '');
    } catch (error) {
      console.error('Failed to update count', error);
    }
  };

  return (
    <div className={`${styles.criterionArea}`}>
      <h3>Antal:</h3>
      <FormControl variant="standard">
        <TextField
          id="count"
          name="count"
          type="number"
          value={loading ? 'loading...' : count} // Display loading or the count
          onChange={handleChange}
          label="Count"
          InputLabelProps={{ shrink: true }}
          disabled={loading} // Disable input during loading
        />
      </FormControl>
    </div>
  );
};

export default CountSettings;
