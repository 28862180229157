const locationCache = new Map<string, string>();

/**
 * Load cache from sessionStorage on initialization
 */
const loadCacheFromSessionStorage = (): void => {
  const cachedData = sessionStorage.getItem('locationCache');
  if (cachedData) {
    const parsedCache = JSON.parse(cachedData);
    for (const [key, value] of Object.entries(parsedCache)) {
      locationCache.set(key, value as string);
    }
  }
};

/**
 * Save the current cache to sessionStorage
 */
const saveCacheToSessionStorage = (): void => {
  const cacheObject = Object.fromEntries(locationCache);
  sessionStorage.setItem('locationCache', JSON.stringify(cacheObject));
};

/**
 * Set a value in the cache
 * @param key - Cache key in the format "<type>:<value>"
 * @param value - Cached value
 */
const setCachedLocation = (key: string, value: string): void => {
  locationCache.set(key, value);
  saveCacheToSessionStorage();
};

/**
 * Get a cached value
 * @param key - Cache key in the format "<type>:<value>"
 * @returns The cached value or undefined if not found
 */
const getCachedLocation = (key: string): string | undefined => {
  return locationCache.get(key);
};

// Load cache on initialization
loadCacheFromSessionStorage();

export const locationCacheService = {
  getCachedLocation,
  setCachedLocation,
};
