import React from 'react';
import { MarkerData } from '../../../models';
import { ChartNetworkIcon, PencilIcon, TrashAltIcon } from '../../misc/icons';
import styles from '../styles/Geo.module.scss';

interface ProximityEntryProps {
  markerData: MarkerData | null;
  editProximity: (edit: boolean) => void;
}

const ProximityEntry: React.FC<ProximityEntryProps> = ({
  markerData,
  editProximity,
}) => {
  return (
    <>
      {markerData?.proximityRadius && (
        <span className={styles.proximity}>
          <ChartNetworkIcon />
          <strong>Närmaste adress. Antal: {markerData.limit}</strong>
          <span
            onClick={() => {
              editProximity(true);
            }}
            className={styles.actions}
          >
            <PencilIcon />
          </span>
          <span className={styles.actions}>
            <TrashAltIcon />
          </span>
        </span>
      )}
    </>
  );
};

export default ProximityEntry;
