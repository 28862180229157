import React from 'react';
import StringItem from './StringItem';
import styles from './styles/StringItem.module.scss';

interface StringItemListProps {
  items: string[];
  labels?: string[];
  itemIcon?: string;
  unit?: string;
  style?: {};
  updateItems: (items: string[]) => void;
}

const StringItemList: React.FC<StringItemListProps> = ({
  items,
  labels,
  itemIcon,
  unit,
  style,
  updateItems,
}) => {
  const deleteItem = (item: string) => {
    const updatedItems = items.filter((listItem) => listItem !== item);
    updateItems(updatedItems);
  };

  return (
    <>
      {items.length > 0 && (
        <div className={styles.itemWrapper} style={style}>
          {items.map((item, index) => (
            <StringItem
              key={index}
              item={item}
              label={labels ? labels[index] : undefined}
              itemIcon={itemIcon}
              deleteItem={deleteItem}
              unit={unit}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default StringItemList;
