import React, { useCallback, useContext, useEffect } from 'react';
import { optimalAPI } from '../../api/';
import { LoadingState } from '../../conf/constants';
import { GlobalContext } from '../../GlobalContext';
import { Selection, SelectionCount } from '../../models';
import styles from './styles/UrvalsMeny.module.scss';
import UrvalAdressTelefon from './UrvalAdressTelefon';

interface UrvalAntalProps {
  selection: Selection;
  selectionCount: SelectionCount;
  setSelectionCount: (selectionCount: SelectionCount) => void;
}

const UrvalAntal: React.FC<UrvalAntalProps> = ({
  selection,
  selectionCount,
  setSelectionCount,
}) => {
  const context = useContext(GlobalContext)!;
  const { globalObject, setGlobalObject } = context;

  const performUpdate = useCallback(async () => {
    if (selection && selection.criteria) {
      setGlobalObject({ ...globalObject, loadingState: LoadingState.LOADING });
      try {
        const refNo = globalObject.refNo;

        if (!refNo) {
          console.error('No reference number provided in the URL.');
          return;
        }

        const countResult = await optimalAPI.updateSelection(selection, refNo);
        setSelectionCount(countResult);
      } finally {
        setGlobalObject({ ...globalObject, loadingState: LoadingState.DONE });
      }
    }
  }, [selection, setSelectionCount]);

  // On critera change:
  useEffect(() => {
    performUpdate();
  }, [selection.criteria, performUpdate]);

  return (
    <div className={styles.countWrapper}>
      <UrvalAdressTelefon
        antalCount={selectionCount.count}
        antalGross={selectionCount.grossCount}
        antalPhoneCount={0}
        antalPhoneGross={0}
      />
    </div>
  );
};

export default UrvalAntal;
