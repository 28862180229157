import React from 'react';

const NumberFormatter: React.FC<{ value: number }> = ({ value }) => {
  // Create an Intl.NumberFormat object
  const formatter = new Intl.NumberFormat('sv-SE', {
    useGrouping: true,
  });

  // Format the value using the formatter
  const formattedValue = formatter.format(value);

  return <span>{formattedValue}</span>;
};

export default NumberFormatter;
