import React, { useState } from 'react';
import { Units } from '../../../conf/constants'; // Import the units
import styles from './styles/Span.module.scss';

interface NumberSpanProps {
  numberSpan?: string[]; // [from-to] or [from] (optional for addMode)
  setNumberSpan: (numberSpan: string) => void;
  unit: Units;
  addMode?: boolean; // Enables add button mode
  maxSize?: number;
  minSize?: number;
  labelFrom?: string;
  labelTo?: string;
  width?: string;
}

const NumberSpan: React.FC<NumberSpanProps> = ({
  numberSpan = [], // Default to empty array if not provided
  setNumberSpan,
  unit,
  addMode = false, // Default to false for normal mode
  maxSize,
  minSize,
  labelFrom,
  labelTo,
  width,
}) => {
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [error, setError] = useState<string>(''); // Error message for validation feedback

  const isValidNumber = (value: string): boolean => /^\d*$/.test(value);

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidNumber(value)) {
      setFrom(value);
      if (unit === Units.SQUARE_METERS && (!isValidNumber(to) || to < value)) {
        setTo(value);
      }
      setError(''); // Clear errors when editing
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidNumber(value)) {
      setTo(value);
      setError(''); // Clear errors when editing
    }
  };

  const validateAndUpdate = (): boolean => {
    const fromValue = Number(from || '0');
    let toValue = Number(to || '0');

    // Perform validation
    if (fromValue === 0 && toValue === 0) {
      setError('Spannet får inte vara 0 eller 0-0.');
      return false;
    }
    if (unit === Units.AGE && toValue === 0) {
      toValue = 125;
    }
    if (maxSize && fromValue.toString().length > maxSize) {
      setError(`Värdet får inte vara längre än ${maxSize} positioner`);
      return false;
    }
    if (minSize && fromValue.toString().length < minSize) {
      setError(`Värdet får inte vara kortare än ${minSize} positioner`);
      return false;
    }
    if (toValue === 0 && fromValue > 0 && unit === Units.ZIP) {
      toValue = fromValue;
    }
    if (fromValue > toValue) {
      setError(
        '"Från" värdet måste vara mindre än eller lika med "Till" värdet.',
      );
      return false;
    }

    // Update numberSpan only when the input is valid
    const newSpan =
      fromValue === toValue ? `${fromValue}` : `${fromValue}-${toValue}`;
    setNumberSpan(newSpan);
    return true;
  };

  const handleBlur = () => {
    if (from === '' || to === '') {
      setError('Båda fälten måste fyllas i.');
    } else {
      validateAndUpdate();
    }
  };

  const handleAddSpan = () => {
    if (validateAndUpdate()) {
      // Clear inputs and error on successful add
      setFrom('');
      setTo('');
      setError('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (addMode && e.key === 'Enter') {
      handleAddSpan(); // Add span when Enter is pressed
    }
  };

  const getUnit = (unit: string): string => {
    return unit !== Units.ZIP ? unit : '';
  };

  return (
    <span style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={styles.spanContainer}>
        <div className={styles.inputWrapper}>
          <label className={styles.label} htmlFor="fromValue">
            {labelFrom ? labelFrom : 'Från'}:
          </label>
          <input
            type="text"
            value={from}
            onChange={handleFromChange}
            onBlur={!addMode ? handleBlur : undefined} // Only validate on blur in non-addMode
            className={styles.numberInput}
            placeholder="Från"
            style={{ ...(width && { width }) }}
          />
        </div>
        <span className={styles.unit}>{getUnit(unit)}</span>
        <span className={styles.dash}>–</span>

        <div className={styles.inputWrapper}>
          <label className={styles.label} htmlFor="toValue">
            {labelTo ? labelTo : 'Till'}:
          </label>
          <input
            type="text"
            value={to}
            onChange={handleToChange}
            onKeyDown={handleKeyPress} // Trigger add on Enter
            onBlur={!addMode ? handleBlur : undefined} // Only validate on blur in non-addMode
            className={styles.numberInput}
            placeholder="Till"
            style={{ ...(width && { width }) }}
          />
        </div>
        <span className={styles.unit}>{getUnit(unit)}</span>

        {addMode && (
          <button
            onClick={handleAddSpan}
            className={styles.addButton}
            title="Lägg till"
          >
            +
          </button>
        )}
      </div>
      <div className={styles.error}>{error}</div>
    </span>
  );
};

export default NumberSpan;
