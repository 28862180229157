import React, { useEffect, useState } from 'react';
import styles from './styles/Span.module.scss';

interface DateSpanProps {
  dateSpan: string | undefined;
  setDateSpan: (dateSpan: string) => void;
}

const DateSpan: React.FC<DateSpanProps> = ({ dateSpan, setDateSpan }) => {
  const [fromDate, setFromDate] = useState<string | undefined>(undefined);
  const [toDate, setToDate] = useState<string | undefined>(undefined);
  const [lastDateSpan, setLastDateSpan] = useState<string | undefined>(
    dateSpan,
  );

  const MIN_DATE = '2013-03-21';

  // Helper functions to convert formats
  const convertToInputFormat = (date: string) =>
    `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;

  const convertToYYYYMMDD = (date: string) => date.replace(/-/g, '');

  useEffect(() => {
    if (dateSpan && dateSpan !== lastDateSpan) {
      const dates = dateSpan.split('-');
      let newFromDate, newToDate;

      if (dates.length === 1) {
        newFromDate = convertToInputFormat(dates[0]);
        newToDate = convertToInputFormat(dates[0]);
      } else if (dates.length === 2) {
        newFromDate = convertToInputFormat(dates[0]);
        newToDate = convertToInputFormat(dates[1]);
      }

      if (newFromDate !== fromDate || newToDate !== toDate) {
        setFromDate(newFromDate);
        setToDate(newToDate);
        setLastDateSpan(dateSpan); // Update the last processed dateSpan to avoid re-processing
      }
    }
  }, [dateSpan, fromDate, toDate, lastDateSpan]);

  useEffect(() => {
    const formattedDateSpan =
      fromDate && toDate && fromDate !== toDate
        ? `${convertToYYYYMMDD(fromDate)}-${convertToYYYYMMDD(toDate)}`
        : fromDate
          ? convertToYYYYMMDD(fromDate)
          : undefined;

    // Avoid setting dateSpan if it's already in the correct format
    if (formattedDateSpan && formattedDateSpan !== dateSpan) {
      setDateSpan(formattedDateSpan);
      setLastDateSpan(formattedDateSpan); // Update last processed to prevent circular updates
    }
  }, [fromDate, toDate, setDateSpan, dateSpan]);

  return (
    <div className={styles.spanContainer}>
      <div className={styles.inputWrapper}>
        <label className={styles.label} htmlFor="fromDate">
          Från och med:
        </label>
        <input
          id="fromDate"
          type="date"
          className={styles.dateInput}
          value={fromDate || ''}
          onChange={(e) => setFromDate(e.target.value)}
          min={MIN_DATE}
          max={toDate || ''}
        />
      </div>
      <span className={styles.dash}>–</span>
      <div className={styles.inputWrapper}>
        <label className={styles.label} htmlFor="toDate">
          Till och med:
        </label>
        <input
          id="toDate"
          type="date"
          className={styles.dateInput}
          value={toDate || ''}
          onChange={(e) => setToDate(e.target.value)}
          min={fromDate || MIN_DATE}
        />
      </div>
    </div>
  );
};

export default DateSpan;
