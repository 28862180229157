import React from 'react';
import { Units } from '../../conf/constants';
import CheckboxLabel from '../interface/global/CheckboxLabel';
import NumberSpan from '../interface/global/NumberSpan';
import StringItemList from '../interface/global/StringItemList';
import ToggleButton from '../interface/global/ToggleButton';
import YearMonthSpan from '../interface/global/YearMonthSpan';
import { HouseIcon } from '../misc/icons';
import Acreage from './Acreage';
import Brf from './Brf';
import HousingValue from './HousingValue';
import styles from './styles/CriteriaGroup.module.scss';

interface HousingProps {
  active: boolean;
  housingType: ('V' | 'B' | 'H' | 'X')[];
  roomCount: ('1' | '2' | '3' | '4' | '5' | '6-30')[];
  housingArea: string[];
  housingValue: string[];
  brfOrgNo: string[];
  acreage: string[];
  constructionYear: string[];
  updateSelectionCriteria: (path: string[], value: any) => void;
}

const HousingCriteriaGroup: React.FC<HousingProps> = ({
  active,
  housingType,
  housingArea,
  roomCount,
  housingValue,
  brfOrgNo,
  acreage,
  constructionYear,
  updateSelectionCriteria,
}) => {
  const setHousingType = (items: ('V' | 'B' | 'H' | 'X')[]) => {
    updateSelectionCriteria(['criteria', 'housingType'], items);
  };
  const setHousingArea = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'housingArea'], items);
  };
  const setRoomCount = (items: ('1' | '2' | '3' | '4' | '5' | '6-30')[]) => {
    updateSelectionCriteria(['criteria', 'roomCount'], items);
  };
  const setHousingValue = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'housingValue'], items);
  };
  const setBrfOrgNo = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'brfOrgNo'], items);
  };
  const setAcreage = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'acreage'], items);
  };
  const setConstructionYear = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'constructionYear'], items);
  };

  const handleToggle = (value: 'V' | 'B' | 'H' | 'X') => {
    if (housingType.includes(value)) {
      // Remove housing Type, also remove depending critiera
      if (value === 'V') {
        setAcreage([]);
        setConstructionYear([]);
      } else if (value === 'B') {
        setBrfOrgNo([]);
        setRoomCount([]);
      }
      if (
        housingType.length === 0 ||
        (housingType.length === 1 && housingType.includes(value))
      ) {
        setHousingArea([]);
      }
      setHousingType(housingType.filter((item) => item !== value));
    } else {
      setHousingType([...housingType, value]);
    }
  };

  const handleChange = (value: string, checked: boolean) => {
    const typedValue = value as '1' | '2' | '3' | '4' | '5' | '6-30';

    if (checked) {
      setRoomCount([...roomCount, typedValue]);
    } else {
      setRoomCount(roomCount.filter((item) => item !== value));
    }
  };

  return (
    <>
      {active && (
        <div className={`${styles.criterionArea} ${styles.CriteriaGroup}`}>
          <h3>
            <HouseIcon />
            &nbsp;Boendeform:
          </h3>
          <div className={`${styles.iconButtonMenu} ${styles.marginBottom}`}>
            <ToggleButton
              label="Villa"
              iconName="house" // Use an appropriate icon name
              isSelected={housingType.includes('V')}
              onClick={() => handleToggle('V')}
            />
            <ToggleButton
              label="Bostadsrätt"
              iconName="building" // Use an appropriate icon name
              isSelected={housingType.includes('B')}
              onClick={() => handleToggle('B')}
            />
            <ToggleButton
              label="Hyresrätt"
              iconName="hr" // Use an appropriate icon name
              isSelected={housingType.includes('H')}
              onClick={() => handleToggle('H')}
            />
            <ToggleButton
              label="Övrigt"
              iconName="iglo" // Use an appropriate icon name
              isSelected={housingType.includes('X')}
              onClick={() => handleToggle('X')}
            />
            {/*<label className={housingType.includes('X') ? styles.checked : ''}>
              <span>Fritidshus</span>
              <input
                type="checkbox"
                value="X"
                checked={housingType.includes('X')}
                onChange={handleChange}
              />
            </label> */}
          </div>
          {housingType.length > 0 && (
            <div className={styles.subBox}>
              <h3>Alternativ</h3>
              <span className={styles.criteriaLabel}>Välj boyta:</span>
              <NumberSpan
                numberSpan={housingArea ?? []}
                setNumberSpan={(numberSpan) =>
                  setHousingArea([...housingArea, numberSpan])
                }
                unit={Units.SQUARE_METERS}
                addMode={true}
              />
              <StringItemList
                items={housingArea}
                unit={Units.SQUARE_METERS}
                updateItems={setHousingArea}
              />
              {/* HousingValue can not be included yet, no permission in SWS */}
              {false && (
                <HousingValue
                  housingValue={housingValue ?? []}
                  setHousingValue={setHousingValue}
                />
              )}
            </div>
          )}
          {housingType.includes('B') && (
            <div className={styles.subBox}>
              <h3>Alternativ för BRF</h3>
              <Brf brfOrgNo={brfOrgNo} setBrfOrgNo={setBrfOrgNo} />
              <div className={styles.criteriaLabel}>Välj antal rum:</div>
              <div className={styles.menu}>
                <CheckboxLabel
                  checked={roomCount.includes('1')}
                  value="1"
                  label="1:or"
                  setValue={handleChange}
                />
                <CheckboxLabel
                  checked={roomCount.includes('2')}
                  value="2"
                  label="2:or"
                  setValue={handleChange}
                />
                <CheckboxLabel
                  checked={roomCount.includes('3')}
                  value="3"
                  label="3:or"
                  setValue={handleChange}
                />
                <CheckboxLabel
                  checked={roomCount.includes('4')}
                  value="4"
                  label="4:or"
                  setValue={handleChange}
                />
                <CheckboxLabel
                  checked={roomCount.includes('5')}
                  value="5"
                  label="5:or"
                  setValue={handleChange}
                />
                <CheckboxLabel
                  checked={roomCount.includes('6-30')}
                  value="6-30"
                  label="6:or+"
                  setValue={handleChange}
                />
              </div>
            </div>
          )}
          {housingType.includes('V') && (
            <div className={styles.subBox}>
              <h3>Alternativ för Villa</h3>
              <div className={styles.criteriaLabel}>Välj byggår:</div>
              <YearMonthSpan
                setYearMonthSpan={(span) =>
                  setConstructionYear([...constructionYear, span])
                }
                onlyYear={true}
              />
              <StringItemList
                items={constructionYear}
                updateItems={setConstructionYear}
              />
              <Acreage acreage={acreage} setAcreage={setAcreage} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HousingCriteriaGroup;
