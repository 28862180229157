import React from 'react';
import Icon from '../../misc/Icon'; // Assuming Icon is located in this path
import styles from './styles/CheckboxLabel.module.scss';

interface CheckboxLabelProps {
  checked: boolean;
  value: string;
  label: string;
  setValue: (value: string, checked: boolean) => void;
}

const CheckboxLabel: React.FC<CheckboxLabelProps> = ({
  checked,
  value,
  label,
  setValue,
}) => {
  const handleClick = () => {
    setValue(value, !checked); // Trigger onClick with the value
  };

  return (
    <span
      className={`${styles.CheckboxLabel} ${checked ? styles.active : ''}`}
      onClick={handleClick}
      title={label}
    >
      <Icon
        iconName={checked ? 'checked' : 'unchecked'}
        disableHover={true}
        color={checked ? '#fff' : '#000'}
        className="mediumIcon"
      />
      <span>{label}</span>
    </span>
  );
};

export default CheckboxLabel;
