import React, { useEffect, useState } from 'react';
import Spinner from '../../misc/Spinner';
import { HouseIcon } from '../../misc/icons';
import NumberFormatter from './NumberFormatter';
import styles from './styles/CountDisplay.module.scss';

interface CountDisplayProps {
  count: number | undefined;
  type: 'ADDRESS' | 'NONE';
  style?: {};
}

const CountDisplay: React.FC<CountDisplayProps> = ({ count, type, style }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (count !== undefined) {
      setIsHighlighted(true);
      const timeout = setTimeout(() => setIsHighlighted(false), 500); // Highlight duration
      return () => clearTimeout(timeout);
    }
  }, [count]);

  return (
    <div
      className={`${styles.CountDisplay} ${
        isHighlighted ? styles.highlight : ''
      }`}
      style={style}
    >
      {count === undefined ? (
        <Spinner type="dots" color="white" />
      ) : (
        <span>
          <NumberFormatter value={count} />
        </span>
      )}
      {type === 'ADDRESS' && (
        <span className={styles.icon}>
          <HouseIcon />
        </span>
      )}
    </div>
  );
};

export default CountDisplay;
