import React, { useEffect, useRef, useState } from 'react';
import { optimalAPI } from '../../api'; // Import your API
import { StreetAddress } from '../../models';
import CountDisplay from '../interface/global/CountDisplay';
import TextInputEdit from '../interface/global/TextInputEdit';
import { TrashAltIcon } from '../misc/icons';
import styles from './styles/StreetAddress.module.scss';

interface StreetAddressProps {
  active: boolean;
  refNo: string;
  googleLoaded: boolean;
  streetAddress: StreetAddress[];
  setStreetAddress: (items: StreetAddress[]) => void;
}

const StreetAddresses: React.FC<StreetAddressProps> = ({
  active,
  refNo,
  googleLoaded,
  streetAddress,
  setStreetAddress,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [streetNumber, setStreetNumber] = useState<string[]>([]);
  const [streetNumberParity, setStreetNumberParity] = useState<
    'ODD' | 'EVEN' | ''
  >('');
  const [countArray, setCountArray] = useState<(number | undefined)[]>([]); // To store counts for each address

  useEffect(() => {
    if (!googleLoaded) return;

    if (inputRef.current) {
      const autocompleteInstance = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['address'],
          componentRestrictions: { country: 'SE' },
        },
      );

      setAutocomplete(autocompleteInstance);

      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();

        if (place && place.geometry) {
          const addressComponents = place.address_components;

          if (addressComponents !== undefined) {
            const streetName =
              addressComponents.find((c) => c.types.includes('route'))
                ?.long_name || '';
            const streetNumberSearch =
              addressComponents.find((c) => c.types.includes('street_number'))
                ?.long_name || null;
            const zip =
              addressComponents.find((c) => c.types.includes('postal_code'))
                ?.long_name || '';
            const city =
              addressComponents.find((c) => c.types.includes('locality'))
                ?.long_name ||
              addressComponents.find((c) => c.types.includes('postal_town'))
                ?.long_name ||
              '';

            // Handle both cases where street number is present or not
            const finalStreetNumber =
              streetNumber.length > 0
                ? streetNumber
                : streetNumberSearch
                  ? [streetNumberSearch]
                  : [];

            const newStreetAddress: StreetAddress = {
              streetName,
              streetNumber: finalStreetNumber,
              streetNumberParity: streetNumberParity || undefined,
              zip: zip.replaceAll(' ', ''),
              city,
            };

            setStreetAddress([...streetAddress, newStreetAddress]);
            setStreetNumber([]);
            setStreetNumberParity('');
            if (inputRef.current) inputRef.current.value = '';
          } else {
            console.log('failed to read the data from Google');
          }
        } else {
          // Case: The user selected a street without a street number
          if (place && place.address_components) {
            const addressComponents = place.address_components;

            const streetName =
              addressComponents.find((c) => c.types.includes('route'))
                ?.long_name || '';
            const city =
              addressComponents.find((c) => c.types.includes('locality'))
                ?.long_name ||
              addressComponents.find((c) => c.types.includes('postal_town'))
                ?.long_name ||
              '';

            const newStreetAddress: StreetAddress = {
              streetName,
              streetNumber: [], // Empty street number array since no specific number was provided
              streetNumberParity: undefined,
              zip: '', // No zip provided in this case
              city,
            };

            setStreetAddress([...streetAddress, newStreetAddress]);
            setStreetNumber([]);
            setStreetNumberParity('');
            if (inputRef.current) inputRef.current.value = '';
          }
        }
      });
    }
  }, [
    googleLoaded,
    streetNumber,
    streetNumberParity,
    streetAddress,
    setStreetAddress,
  ]);

  useEffect(() => {
    const updateCounts = async () => {
      const counts = await Promise.all(
        streetAddress.map(async (address) => {
          try {
            const result = await optimalAPI.countForAddress({
              refNo,
              streetAddress: address,
            });
            return result; // Return the count result for each address
          } catch (error) {
            console.error('Failed to fetch count for address', address, error);
            return undefined;
          }
        }),
      );
      setCountArray(counts); // Update the countArray state
    };

    if (streetAddress.length > 0) {
      updateCounts(); // Call updateCounts when streetAddress array changes
    }
  }, [streetAddress, refNo]); // Dependency on streetAddress and refNo

  const handleStreetNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trim();
    setStreetNumber(input ? input.split(',').map((num) => num.trim()) : []);
  };

  const handleStreetNumberParityChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setStreetNumberParity(e.target.value as 'ODD' | 'EVEN' | '');
  };

  const handleAddressFieldChange = (
    index: number,
    field: keyof StreetAddress,
    value: string | string[],
  ) => {
    const updatedAddresses = streetAddress.map((address, i) =>
      i === index ? { ...address, [field]: value } : address,
    );
    setStreetAddress(updatedAddresses);
  };

  const handleRemoveAddress = (index: number) => {
    const updatedAddresses = streetAddress.filter((_, i) => i !== index);
    setStreetAddress(updatedAddresses);
  };

  return (
    <>
      {active && (
        <div className={`${styles.CriteriaGroup} ${styles.subBox}`}>
          <h3>Gatuadress</h3>
          <table className={styles.optimalTable}>
            <thead>
              <tr>
                <th>Gatunamn</th>
                <th>Gatunummer</th>
                <th>Stad</th>
                <th>Udda/Jämna</th>
                <th>Antal</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {streetAddress.map((address, index) => {
                const currentAddress = streetAddress[index]; // Safely store current address

                return (
                  <tr key={index}>
                    <td>
                      <TextInputEdit
                        text={currentAddress?.streetName || ''}
                        label=""
                        placeholder="Gatunamn"
                        setText={(text: string) =>
                          handleAddressFieldChange(index, 'streetName', text)
                        }
                        updateEachStroke={false}
                        tight={true}
                      />
                    </td>
                    <td>
                      <TextInputEdit
                        text={
                          currentAddress?.streetNumber
                            ? currentAddress.streetNumber.join(', ')
                            : ''
                        } // Convert array to string
                        label=""
                        placeholder="Gatunummer"
                        setText={(text: string) =>
                          handleAddressFieldChange(
                            index,
                            'streetNumber',
                            text.split(',').map((num) => num.trim()),
                          )
                        }
                        updateEachStroke={false}
                        tight={true}
                        size="MEDIUM"
                      />
                    </td>
                    <td>{currentAddress?.city || ''}</td>
                    <td>
                      <select
                        value={currentAddress?.streetNumberParity || ''}
                        onChange={(e) =>
                          handleAddressFieldChange(
                            index,
                            'streetNumberParity',
                            e.target.value,
                          )
                        }
                        className={styles.dropdown}
                      >
                        <option value="">Alla</option>
                        <option value="ODD">Udda</option>
                        <option value="EVEN">Jämna</option>
                      </select>
                    </td>
                    <td>
                      <CountDisplay count={countArray[index]} type="ADDRESS" />
                    </td>
                    <td
                      className={styles.trash}
                      onClick={() => handleRemoveAddress(index)}
                    >
                      <TrashAltIcon />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Sök gatuadress"
                    className={styles.searchInputText}
                    disabled={!googleLoaded}
                  />
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default StreetAddresses;
