import React, { useEffect, useState } from 'react';
import { ActiveMenuItems, MenuItem, menuItemMap } from '../../conf/constants';
import { Criteria, Selection } from '../../models';
import { optimalUtil } from '../../util';
import styles from './styles/UrvalsMeny.module.scss';
import UrvalsMenyButton from './UrvalsMenyButton';

interface UrvalsMenyProps {
  activeMenuComponents: ActiveMenuItems;
  selection: Selection;
  setActiveMenuComponents: (activeMenuComponents: ActiveMenuItems) => void;
}

const UrvalsMeny: React.FC<UrvalsMenyProps> = ({
  activeMenuComponents,
  selection,
  setActiveMenuComponents,
}) => {
  const [lastActiveComponent, setLastActiveComponent] =
    useState<MenuItem | null>(null);

  // Triggers when the selection object changes to update which menu items should be active
  useEffect(() => {
    let updatedComponents: ActiveMenuItems = {};
    const criteria: Criteria | null = selection.criteria
      ? selection.criteria
      : null;
    // criteria is allowed to be undefined, ensure it's not
    if (criteria) {
      // Iterate over the menuItemMap to set active states
      Object.keys(criteria).forEach((criteriaKey) => {
        const menuItem: MenuItem = menuItemMap[criteriaKey]; // Get the corresponding MenuItem
        if (
          menuItem &&
          optimalUtil.isNonEmptyArray(criteria[criteriaKey as keyof Criteria])
        ) {
          updatedComponents[menuItem] = true;
        }
      });
    }

    // Outside of criteria, Geo visibility is also controlled by proximity:
    if (
      selection.proximity &&
      selection.limit &&
      !updatedComponents[MenuItem.geo]
    ) {
      updatedComponents[MenuItem.geo] = true;
    }

    if (lastActiveComponent) {
      updatedComponents[lastActiveComponent] = true;
    }

    setActiveMenuComponents(updatedComponents);
  }, [selection, setActiveMenuComponents, lastActiveComponent]);

  const toggleComponent = (component: MenuItem) => {
    // Toggle component's active state
    const newActiveComponents = {
      ...activeMenuComponents,
      [component]: !activeMenuComponents[component],
    };

    setActiveMenuComponents(newActiveComponents);

    // Track the last activated component
    setLastActiveComponent(newActiveComponents[component] ? component : null);
  };

  return (
    <div className={styles.selectionMenu}>
      {Object.entries(MenuItem).map(([key, value]) => (
        <UrvalsMenyButton
          key={key}
          component={key}
          isActive={activeMenuComponents[value]}
          onClick={() => toggleComponent(value)}
          label={value}
        />
      ))}
    </div>
  );
};

export default UrvalsMeny;
