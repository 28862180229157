import React, { useContext, useEffect, useState } from 'react';
import { optimalAPI } from '../../api';
import { locationCacheService } from '../../cache/LocationCacheService';
import { LoadingState } from '../../conf/constants';
import { GlobalContext } from '../../GlobalContext';
import { CriterionCountPayload, SearchResult } from '../../models';
import { optimalUtil } from '../../util';
import CountDisplay from '../interface/global/CountDisplay';
import PickFromSearch from '../interface/global/PickFromSearch';
import { TrashAltIcon } from '../misc/icons';
import Spinner from '../misc/Spinner';
import styles from './styles/CriteriaGroup.module.scss';

interface MoveLocationListProps {
  countyBeforeLastMove: string[];
  excludingCountyBeforeLastMove: string[];
  municipalityBeforeLastMove: string[];
  excludingMunicipalityBeforeLastMove: string[];
  cityBeforeLastMove: string[];
  excludingCityBeforeLastMove: string[];
  toggle: (type: string, value: string, exclude: boolean) => void;
  remove: (type: string, value: string, exclude: boolean) => void;
  addResult: (location: SearchResult) => void;
}

const MoveLocationList: React.FC<MoveLocationListProps> = ({
  countyBeforeLastMove,
  excludingCountyBeforeLastMove,
  municipalityBeforeLastMove,
  excludingMunicipalityBeforeLastMove,
  cityBeforeLastMove,
  excludingCityBeforeLastMove,
  toggle,
  remove,
  addResult,
}) => {
  return (
    <table className={styles.optimalTable}>
      <thead>
        <tr>
          <th className={styles.small}>Flyttat</th>
          <th>Område</th>
          <th>Antal</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {countyBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`county-blm-${item}`}
            type="r"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingCountyBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`excluding-county-blm-${item}`}
            type="r"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {municipalityBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`municipality-blm-${item}`}
            type="k"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingMunicipalityBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`excluding-municipality-blm-${item}`}
            type="k"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {cityBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`city-blm-${item}`}
            type="p"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingCityBeforeLastMove.map((item) => (
          <MoveLocationListRow
            key={`excluding-city-blm-${item}`}
            type="p"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        <tr>
          <td colSpan={4}>
            <PickFromSearch
              searchIndex="locations"
              addResult={addResult}
              searchLabel="kommun, region, postort"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MoveLocationList;

const MoveLocationListRow: React.FC<{
  type: string;
  value: string;
  exclude?: boolean;
  toggle: (type: string, value: string, exclude: boolean) => void;
  remove: (type: string, value: string, exclude: boolean) => void;
}> = ({
  type,
  value,
  exclude = false, // Default value for exclude
  toggle,
  remove,
}) => {
  const [name, setName] = useState<string>('');
  const [count, setCount] = useState<string | null>(null);
  const { globalObject } = useContext(GlobalContext)!;
  const refNo = globalObject.refNo;

  useEffect(() => {
    const fetchLocationName = async () => {
      const typeCode = optimalUtil.getLocationTypeCode(type);
      let loadedName = locationCacheService.getCachedLocation(
        `${typeCode}:${value}`,
      );
      if (loadedName === undefined) {
        try {
          const result = await optimalAPI.getLocation(value, typeCode);
          loadedName = result?.search ?? type; // Fallback to `type` if result is null
          locationCacheService.setCachedLocation(
            `${typeCode}:${value}`,
            loadedName,
          );
        } catch (error) {
          console.error('Error fetching location:', error);
          loadedName = type; // Fallback to `type` in case of an error
        }
      }
      setName(loadedName);
    };

    if (type !== 'z') {
      fetchLocationName(); // Call the async function
    } else {
      setName(value);
    }
  }, [type, value]); // Add dependencies for type and value

  useEffect(() => {
    const fetchCount = async () => {
      const payload: CriterionCountPayload = {
        refNo: refNo,
        criterion: `${optimalUtil.getLocationCriterion(type, exclude)}BeforeLastMove`,
        value: value,
      };
      try {
        const result = await optimalAPI.countForCriteria(payload);
        if (result !== null) {
          setCount(result.toString());
        }
      } catch (error) {
        console.error('Can not fetch count for location.');
      }
    };
    if (globalObject.loadingState === LoadingState.DONE) {
      fetchCount();
    }
  }, [globalObject.loadingState]);

  return (
    <tr>
      <td className={styles.small}>
        <select
          value={exclude.toString()}
          onChange={(e) => toggle(type, value, e.target.value === 'true')}
        >
          <option value="false">Flyttat från</option>
          <option value="true">Ej flyttat från</option>
        </select>
      </td>
      <td>
        <strong>{optimalUtil.getLocationTypeCodeName(type)}</strong>: {name}
      </td>
      <td className={styles.small}>
        {count === null && <Spinner color="red" type="dots" />}
        {count !== null && (
          <CountDisplay
            count={parseInt(count)}
            type="ADDRESS"
            style={{ marginRight: '14px' }}
          />
        )}
      </td>
      <td className={styles.tiny}>
        <span
          onClick={() => remove(type, value, exclude)}
          className={`${styles.redIcon} ${styles.mediumIcon}`}
        >
          <TrashAltIcon />
        </span>
      </td>
    </tr>
  );
};
