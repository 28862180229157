import React from 'react';
import Icon from '../../misc/Icon';
import styles from './styles/StringItem.module.scss';

interface StringItemProps {
  item: string;
  label?: string;
  unit?: string;
  itemIcon?: string;
  deleteItem: (item: string) => void;
}

const StringItem: React.FC<StringItemProps> = ({
  item,
  label,
  unit,
  deleteItem,
  itemIcon,
}) => {
  const color = '#fafafa';
  return (
    <div className={styles.itemContainer}>
      {itemIcon && (
        <Icon
          iconName={itemIcon}
          color={color}
          hoverColor={color}
          className={`${styles.mediumSmallIcon} ${styles.itemIcon}`}
        />
      )}
      <span
        className={`${styles.textContent} ${itemIcon ? styles.adjustLeftPadding : ''} `}
      >
        {label ? label : item} {unit ? unit : ''}
      </span>
      <span className={styles.removeIcon} onClick={() => deleteItem(item)}>
        <Icon
          iconName="close"
          color={color}
          className={styles.mediumIcon}
          hoverColor={color}
        />
      </span>
    </div>
  );
};

export default StringItem;
