//Application update state
export enum LoadingState {
  UNSET,
  LOADING,
  DONE,
}

// Google maps libraries used
export const libraries: ('drawing' | 'places' | 'geometry' | 'marker')[] = [
  'drawing',
  'places',
  'geometry',
  'marker',
];

// Lägg till items nedan för nya menyval
export enum MenuItem {
  housing = 'Boendeform',
  person = 'Personurval',
  location = 'Adressurval',
  household = 'Hushåll',
  move = 'Flytt',
  geo = 'Karturval',
}

// Criteria till meny-koppling nedan, styr om menyknappen tänds när urvalet är satt
export const menuItemMap: Record<string, MenuItem> = {
  // Person
  age: MenuItem.person,
  birthYearAndMonth: MenuItem.person,
  birthMonth: MenuItem.person,
  gender: MenuItem.person,

  // Geografisk lokation
  county: MenuItem.location,
  excludingCounty: MenuItem.location,
  municipality: MenuItem.location,
  excludingMunicipality: MenuItem.location,
  aRegion: MenuItem.location,
  hRegion: MenuItem.location,
  zip: MenuItem.location,
  excludingZip: MenuItem.location,
  city: MenuItem.location,
  excludingCity: MenuItem.location,
  electoralDistrict: MenuItem.location,
  addressType: MenuItem.location,
  streetAddress: MenuItem.location,

  // Boendeform
  housingType: MenuItem.housing,
  roomCount: MenuItem.housing,
  housingArea: MenuItem.housing,
  housingValue: MenuItem.housing,
  brfOrgNo: MenuItem.housing,

  // Hushåll
  householdComposition: MenuItem.household,
  consumptionPower: MenuItem.household,

  // Flytt-information
  moveDate: MenuItem.move,
  countyBeforeLastMove: MenuItem.move,
  municipalityBeforeLastMove: MenuItem.move,
  zipBeforeLastMove: MenuItem.move,
  cityBeforeLastMove: MenuItem.move,

  // Karturval
  geo: MenuItem.geo,
};

export interface ActiveMenuItems extends Record<string, boolean> {}

export enum Units {
  SQUARE_METERS = 'm²',
  PIECES = 'st',
  HECTARES = 'ha',
  AGE = 'år',
  ZIP = 'zip',
}

export const COLORS = [
  '#1D8BD1',
  '#F1683C',
  '#2AD62A',
  '#9C9AFF',
  '#9C3063',
  '#FF00FF',
  '#FFFF00',
  '#00FFFF',
  '#840084',
  '#840000',
  '#008284',
  '#0000FF',
  '#00CFFF',
  '#CEFFFF',
  '#CEFFCE',
  '#FFFF9C',
  '#9CCFFF',
  '#FF9ACE',
  '#CE9AFF',
  '#FFCF9C',
  '#3165FF',
  '#31CFCE',
  '#9CCF00',
  '#FFCF00',
  '#FF9A00',
  '#FF6500',
];
