import React, { useContext, useEffect, useState } from 'react';
import { optimalAPI } from '../../api';
import { locationCacheService } from '../../cache/LocationCacheService';
import { LoadingState } from '../../conf/constants';
import { GlobalContext } from '../../GlobalContext';
import { CriterionCountPayload } from '../../models';
import { optimalUtil } from '../../util';
import CountDisplay from '../interface/global/CountDisplay';
import { TrashAltIcon } from '../misc/icons';
import Spinner from '../misc/Spinner';
import styles from './styles/CriteriaGroup.module.scss';

interface LocationListProps {
  county: string[];
  excludingCounty: string[];
  municipality: string[];
  excludingMunicipality: string[];
  city: string[];
  excludingCity: string[];
  zip: string[];
  excludingZip: string[];
  toggle: (type: string, value: string, exclude: boolean) => void;
  remove: (type: string, value: string, exclude: boolean) => void;
}

const LocationList: React.FC<LocationListProps> = ({
  county,
  excludingCounty,
  municipality,
  excludingMunicipality,
  city,
  excludingCity,
  zip,
  excludingZip,
  toggle,
  remove,
}) => {
  return (
    <table className={styles.optimalTable}>
      <thead>
        <tr>
          <th className={styles.small}>Boende</th>
          <th>Område</th>
          <th>Antal</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {county.map((item) => (
          <LocationListRow
            key={`county-${item}`}
            type="r"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingCounty.map((item) => (
          <LocationListRow
            key={`excluding-county-${item}`}
            type="r"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {municipality.map((item) => (
          <LocationListRow
            key={`municipality-${item}`}
            type="k"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingMunicipality.map((item) => (
          <LocationListRow
            key={`excluding-municipality-${item}`}
            type="k"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {city.map((item) => (
          <LocationListRow
            key={`city-${item}`}
            type="p"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingCity.map((item) => (
          <LocationListRow
            key={`excluding-city-${item}`}
            type="p"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {zip.map((item) => (
          <LocationListRow
            key={`zip-${item}`}
            type="z"
            value={item}
            toggle={toggle}
            remove={remove}
          />
        ))}
        {excludingZip.map((item) => (
          <LocationListRow
            key={`excluding-zip-${item}`}
            type="z"
            value={item}
            toggle={toggle}
            exclude={true}
            remove={remove}
          />
        ))}
        {county.length === 0 &&
          excludingCounty.length === 0 &&
          city.length === 0 &&
          municipality.length === 0 &&
          excludingMunicipality.length === 0 &&
          excludingCity.length === 0 &&
          zip.length === 0 &&
          excludingZip.length === 0 && (
            <tr>
              <td colSpan={4} style={{ fontStyle: 'italic' }}>
                Inga områden i urvalet
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
};

export default LocationList;

const LocationListRow: React.FC<{
  type: string;
  value: string;
  exclude?: boolean;
  toggle: (type: string, value: string, exclude: boolean) => void;
  remove: (type: string, value: string, exclude: boolean) => void;
}> = ({
  type,
  value,
  exclude = false, // Default value for exclude
  toggle,
  remove,
}) => {
  const [name, setName] = useState<string>('');
  const [count, setCount] = useState<string | null>(null);
  const { globalObject } = useContext(GlobalContext)!;
  const refNo = globalObject.refNo;

  useEffect(() => {
    const fetchLocationName = async () => {
      const typeCode = optimalUtil.getLocationTypeCode(type);
      let loadedName = locationCacheService.getCachedLocation(
        `${typeCode}:${value}`,
      );
      if (loadedName === undefined) {
        try {
          const result = await optimalAPI.getLocation(value, typeCode);
          loadedName = result?.search ?? type; // Fallback to `type` if result is null
          locationCacheService.setCachedLocation(
            `${typeCode}:${value}`,
            loadedName,
          );
        } catch (error) {
          console.error('Error fetching location:', error);
          loadedName = type; // Fallback to `type` in case of an error
        }
      }
      setName(loadedName);
    };

    if (type !== 'z') {
      fetchLocationName(); // Call the async function
    } else {
      setName(value);
    }
  }, [type, value]); // Add dependencies for type and value

  useEffect(() => {
    const fetchCount = async () => {
      const payload: CriterionCountPayload = {
        refNo: refNo,
        criterion: optimalUtil.getLocationCriterion(type, exclude),
        value: value,
      };
      try {
        const result = await optimalAPI.countForCriteria(payload);
        if (result !== null) {
          setCount(result.toString());
        }
      } catch (error) {
        console.error('Can not fetch count for location.');
      }
    };
    if (globalObject.loadingState === LoadingState.DONE) {
      fetchCount();
      console.log('triggered reload', type, value, exclude);
    } else {
      console.log('no reload triggered', type, value, exclude);
    }
  }, [globalObject.loadingState]);

  return (
    <tr>
      <td className={styles.small}>
        <select
          value={exclude.toString()}
          onChange={(e) => toggle(type, value, e.target.value === 'true')}
        >
          <option value="false">Boende i</option>
          <option value="true">Ej boende i</option>
        </select>
      </td>
      <td>
        <strong>{optimalUtil.getLocationTypeCodeName(type)}</strong>: {name}
      </td>
      <td className={styles.small}>
        {count === null && <Spinner color="red" type="dots" />}
        {count !== null && (
          <CountDisplay
            count={parseInt(count)}
            type="ADDRESS"
            style={{ marginRight: '14px' }}
          />
        )}
      </td>
      <td className={styles.tiny}>
        <span
          onClick={() => remove(type, value, exclude)}
          className={`${styles.redIcon} ${styles.mediumIcon}`}
        >
          <TrashAltIcon />
        </span>
      </td>
    </tr>
  );
};
