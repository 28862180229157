import React, { useState } from 'react';
import {
  AddressBookIcon,
  AgeIcon,
  BiggerPhoneIcon,
  BoxFullIcon,
  BuildingIcon,
  CalendarAltIcon,
  ChartNetworkIcon,
  CheckCircleIcon,
  CheckIcon,
  CheckSolidIcon,
  CheckSquareIcon,
  ChevronCircleLeftIcon,
  ChevronCircleRightIcon,
  CityIcon,
  CloudUploadIcon,
  CogIcon,
  CogsIcon,
  DrawCircleIcon,
  DrawPolygonIcon,
  DrawSquareIcon,
  ElephantIcon,
  ExcelIcon,
  ExpandIcon,
  EyeIcon,
  EyeSlashIcon,
  FalBuildIcon,
  FalHouseIcon,
  FemaleIcon,
  FileSignature2Icon,
  FileSignatureIcon,
  GenderFemaleIcon,
  GenderIcon,
  GenderMaleIcon,
  HomeIcon,
  HouseDayIcon,
  HouseIcon,
  IglooIcon,
  LightbulbOnIcon,
  LinkIcon,
  MagnifierIcon,
  MagnifierSmallIcon,
  MailboxIcon,
  MaleIcon,
  MapMarkerAltIcon,
  MapMarkerAltSlashIcon,
  MapMarkerIcon,
  MobileAltIcon,
  PDFIcon,
  PencilIcon,
  PhoneIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  RandomIcon,
  SaveIcon,
  SearchMinusIcon,
  SearchPlusIcon,
  ShoppingCartIcon,
  SquareIcon,
  TachometerAverageIcon,
  TachometerFastestIcon,
  TachometerFastIcon,
  TachometerSlowestIcon,
  TachometerSlowIcon,
  TimesCircleIcon,
  TimesIcon,
  TrashAltIcon,
  UserAltSlashIcon,
  UserHeadsetIcon,
  UserIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
  UserTimesIcon,
  WalletIcon,
  WarningIcon,
} from './';

/* This is merely a test component to see what icons we have in misc/icons */
const ShowIcons: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const icons = [
    { Component: GenderFemaleIcon, name: 'GenderFemaleIcon' },
    { Component: GenderMaleIcon, name: 'GenderMaleIcon' },
    { Component: CheckSquareIcon, name: 'CheckSquareIcon' },
    { Component: CheckIcon, name: 'CheckIcon' },
    { Component: CheckSolidIcon, name: 'CheckSolidIcon' },
    { Component: CheckCircleIcon, name: 'CheckCircleIcon' },
    { Component: SquareIcon, name: 'SquareIcon' },
    { Component: TimesIcon, name: 'TimesIcon' },
    { Component: PlusCircleIcon, name: 'PlusCircleIcon' },
    { Component: QuestionCircleIcon, name: 'QuestionCircleIcon' },
    { Component: PencilIcon, name: 'PencilIcon' },
    { Component: TrashAltIcon, name: 'TrashAltIcon' },
    { Component: PDFIcon, name: 'PDFIcon' },
    { Component: LinkIcon, name: 'LinkIcon' },
    { Component: ShoppingCartIcon, name: 'ShoppingCartIcon' },
    { Component: ChevronCircleRightIcon, name: 'ChevronCircleRightIcon' },
    { Component: HouseIcon, name: 'HouseIcon' },
    { Component: FalHouseIcon, name: 'FalHouseIcon' },
    { Component: BoxFullIcon, name: 'BoxFullIcon' },
    { Component: BuildingIcon, name: 'BuildingIcon' },
    { Component: FalBuildIcon, name: 'FalBuildIcon' },
    { Component: GenderIcon, name: 'GenderIcon' },
    { Component: ExcelIcon, name: 'ExcelIcon' },
    { Component: AgeIcon, name: 'AgeIcon' },
    { Component: WalletIcon, name: 'WalletIcon' },
    { Component: MapMarkerIcon, name: 'MapMarkerIcon' },
    { Component: ChartNetworkIcon, name: 'ChartNetworkIcon' },
    { Component: AddressBookIcon, name: 'AddressBookIcon' },
    { Component: CityIcon, name: 'CityIcon' },
    { Component: SaveIcon, name: 'SaveIcon' },
    { Component: TimesCircleIcon, name: 'TimesCircleIcon' },
    { Component: FemaleIcon, name: 'FemaleIcon' },
    { Component: MaleIcon, name: 'MaleIcon' },
    { Component: TachometerSlowestIcon, name: 'TachometerSlowestIcon' },
    { Component: TachometerSlowIcon, name: 'TachometerSlowIcon' },
    { Component: TachometerAverageIcon, name: 'TachometerAverageIcon' },
    { Component: TachometerFastIcon, name: 'TachometerFastIcon' },
    { Component: TachometerFastestIcon, name: 'TachometerFastestIcon' },
    { Component: CalendarAltIcon, name: 'CalendarAltIcon' },
    { Component: UserIcon, name: 'UserIcon' },
    { Component: UsersIcon, name: 'UsersIcon' },
    { Component: MobileAltIcon, name: 'MobileAltIcon' },
    { Component: IglooIcon, name: 'IglooIcon' },
    { Component: FileSignatureIcon, name: 'FileSignatureIcon' },
    { Component: FileSignature2Icon, name: 'FileSignature2Icon' },
    { Component: MailboxIcon, name: 'MailboxIcon' },
    { Component: HouseDayIcon, name: 'HouseDayIcon' },
    { Component: LightbulbOnIcon, name: 'LightbulbOnIcon' },
    { Component: HomeIcon, name: 'HomeIcon' },
    { Component: MapMarkerAltSlashIcon, name: 'MapMarkerAltSlashIcon' },
    { Component: EyeSlashIcon, name: 'EyeSlashIcon' },
    { Component: DrawCircleIcon, name: 'DrawCircleIcon' },
    { Component: DrawSquareIcon, name: 'DrawSquareIcon' },
    { Component: DrawPolygonIcon, name: 'DrawPolygonIcon' },
    { Component: SearchPlusIcon, name: 'SearchPlusIcon' },
    { Component: SearchMinusIcon, name: 'SearchMinusIcon' },
    { Component: ExpandIcon, name: 'ExpandIcon' },
    { Component: ChevronCircleLeftIcon, name: 'ChevronCircleLeftIcon' },
    { Component: UserHeadsetIcon, name: 'UserHeadsetIcon' },
    { Component: RandomIcon, name: 'RandomIcon' },
    { Component: CloudUploadIcon, name: 'CloudUploadIcon' },
    { Component: CogsIcon, name: 'CogsIcon' },
    { Component: CogIcon, name: 'CogIcon' },
    { Component: UserPlusIcon, name: 'UserPlusIcon' },
    { Component: UserMinusIcon, name: 'UserMinusIcon' },
    { Component: UserTimesIcon, name: 'UserTimesIcon' },
    { Component: UserAltSlashIcon, name: 'UserAltSlashIcon' },
    { Component: MapMarkerAltIcon, name: 'MapMarkerAltIcon' },
    { Component: ElephantIcon, name: 'ElephantIcon' },
    { Component: WarningIcon, name: 'WarningIcon' },
    { Component: PhoneIcon, name: 'PhoneIcon' },
    { Component: BiggerPhoneIcon, name: 'BiggerPhoneIcon' },
    { Component: EyeIcon, name: 'EyeIcon' },
    { Component: MagnifierIcon, name: 'MagnifierIcon' },
    { Component: MagnifierSmallIcon, name: 'MagnifierSmallIcon' },
  ];

  return (
    <>
      <div onClick={() => setShow(!show)} style={{ cursor: 'pointer' }}>
        Icons
      </div>
      <div style={{ display: show ? 'flex' : 'none', flexWrap: 'wrap' }}>
        <style>
          {`
          .iconTest {
            margin: 10px; 
            textAlign: center;
          }
          .iconTest svg {
            width: 30px;
            height: auto;
          }
        `}
        </style>
        {icons.map(({ Component, name }) => (
          <div key={name} className="iconTest">
            <Component />
            <div>{name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShowIcons;
