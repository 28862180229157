import { Autocomplete } from '@react-google-maps/api';
import React, { useCallback, useRef } from 'react';
import { MagnifierIcon } from '../../misc/icons';
import styles from '../styles/Geo.module.scss';

interface MapSearchProps {
  onPlaceSelected: (
    coordinates: { lat: number; lng: number },
    bounds: google.maps.LatLngBounds | null,
    address: string | null,
  ) => void;
  googleLoaded: boolean;
}

const MapSearch: React.FC<MapSearchProps> = ({
  onPlaceSelected,
  googleLoaded,
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onLoad = useCallback((ref: google.maps.places.Autocomplete) => {
    autocompleteRef.current = ref;
  }, []);

  const onPlaceChanged = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      console.log(place.formatted_address);
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      let bounds = null;
      if (place.geometry.viewport) {
        bounds = new google.maps.LatLngBounds(
          place.geometry.viewport.getSouthWest(),
          place.geometry.viewport.getNorthEast(),
        );
      }
      onPlaceSelected(
        coordinates,
        bounds,
        place.formatted_address ? place.formatted_address : null,
      );
      // Clear the input field when a place is picked
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [onPlaceSelected]);

  if (!googleLoaded) return <div>Loading</div>;

  const options = {
    componentRestrictions: { country: 'SE' },
    types: ['geocode'], // Restrict to addresses, including cities and streets
  };

  return (
    <div className={styles.mapSearch}>
      <MagnifierIcon />
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={options}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder="Sök adress"
          className={styles.input}
        />
      </Autocomplete>
    </div>
  );
};

export default MapSearch;
