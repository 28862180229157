import { GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../../conf/constants';
import googleConfig from '../../../conf/google.json';
import { Coordinates, MarkerData, Shape } from '../../../models';
import { optimalUtil } from '../../../util';
import Spinner from '../../misc/Spinner';
import styles from '../styles/Geo.module.scss';

interface OptimalGoogleMapProps {
  mapCenter: Coordinates;
  mapZoom: number;
  mapBounds: google.maps.LatLngBounds | null;
  googleLoaded: boolean;
  shapes: Shape[];
  editShape: Shape | null;
  placeNeedle: boolean;
  markerData: MarkerData | null;
  setMarkerData: (mapMarkerData: MarkerData) => void;
  addShape: (shape: Shape) => void;
  removeShape: (uuid: string) => void;
  updateShape: (shape: Shape) => void;
}

const OptimalGoogleMap: React.FC<OptimalGoogleMapProps> = ({
  mapCenter,
  mapZoom,
  mapBounds,
  googleLoaded,
  shapes,
  editShape,
  placeNeedle,
  markerData,
  setMarkerData,
  addShape,
  removeShape,
  updateShape,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(
    null,
  );
  const colorIndexRef = useRef(0);
  type ShapeMap = {
    [uuid: string]:
      | google.maps.Polygon
      | google.maps.Circle
      | google.maps.Rectangle;
  };
  const renderedGoogleShapesRef = useRef<ShapeMap>({});
  const mapMarkerRef = useRef<google.maps.Marker | null>(null);

  // Used when shape is edited by click on pencil icon
  const [currentEditShape, setCurrentEditShape] = useState<string | null>(null);

  // Used when marker cicle is edited with Proximity settings dialogue
  const [currentMarkerEditShape, setCurrentmarkerEditShape] = useState<
    string | null
  >(null);
  const [proximityCircle, setProximityCircle] =
    useState<google.maps.Circle | null>(null);

  useEffect(() => {
    if (mapRef.current && !mapMarkerRef.current) {
      mapRef.current.panTo(mapCenter);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (mapRef.current && !mapMarkerRef.current) {
      mapRef.current.setZoom(mapZoom);
    }
  }, [mapZoom]);

  useEffect(() => {
    if (mapBounds && !mapMarkerRef.current) {
      mapRef.current?.fitBounds(mapBounds);
    }
  }, [mapBounds]);

  const clearGoogleMapShapes = () => {
    console.log('Clear the google map shapes');
    Object.values(renderedGoogleShapesRef.current).forEach((mapShape) => {
      mapShape.setMap(null);
    });
    renderedGoogleShapesRef.current = {};
  };

  const handlePlaceNeedle = useCallback(() => {
    console.log('Marker data - place needle');
    //om mapmarkerref är null - skapa ny med markerData
    //om mapmarkerref finns och har samma koordinater, gör inget
    //om mapmarkerref finns och inte har samma, ta bort och visa nya

    if (mapMarkerRef.current !== null) {
      console.log('mamparker ref gogog');
      if (
        markerData &&
        optimalUtil.isSameCoordinates(
          mapMarkerRef.current.getPosition(),
          markerData.coordinates,
        )
      ) {
        // already showing
        return;
      } else {
        // showing other marker, remove
        console.log('remove marker');
        mapMarkerRef.current.setMap(null);
        mapMarkerRef.current = null;
      }
    }
    if (markerData) {
      console.log('marker data gogogo');
      mapMarkerRef.current = new google.maps.Marker({
        position: markerData.coordinates,
        map: mapRef.current,
        title: 'Adress',
      });
    }
  }, [markerData]);

  useEffect(() => {
    console.log('Handle place needle');
    if (placeNeedle || markerData?.coordinates) {
      handlePlaceNeedle();
    }
  }, [placeNeedle, handlePlaceNeedle, markerData]);

  const handleProximityRadius = useCallback(() => {
    console.log('Handle proximity circle', markerData, proximityCircle);
    if (
      markerData &&
      markerData.coordinates &&
      markerData.proximityRadius &&
      markerData.type === 'PROXIMITY'
    ) {
      const circleProps = {
        fillColor: '#00FFFF',
        strokeColor: '#009999',
        map: mapRef.current,
        editable: false,
      };
      if (proximityCircle) {
        const coords = proximityCircle.getCenter();
        const pCoords = markerData.coordinates;
        const radius = proximityCircle.getRadius();
        if (
          coords &&
          (pCoords.lat !== coords.lat() ||
            pCoords.lng !== coords.lng() ||
            markerData.proximityRadius !== radius)
        ) {
          console.log('Updating proximity circle on map');
          proximityCircle.setOptions({
            ...optimalUtil.getCircleProps(markerData),
            ...circleProps,
          });
        } else {
          console.log(
            'No changes seems to have been made:',
            markerData,
            proximityCircle.getRadius(),
          );
        }
      } else {
        console.log('Adding proximity circle to map');
        setProximityCircle(
          new google.maps.Circle({
            ...optimalUtil.getCircleProps(markerData),
            ...circleProps,
          }),
        );
      }
    } else {
      if (proximityCircle) {
        proximityCircle.setMap(null);
        setProximityCircle(null);
      }
    }
  }, [proximityCircle, markerData]);

  const createUpdateMarkerCircle = () => {
    console.log('Create update marker circle');
    if (markerData) {
      console.log('createupdatemarker', currentMarkerEditShape);
      if (currentMarkerEditShape === null) {
        // create circle on map
        const color = COLORS[colorIndexRef.current % COLORS.length];
        const name = `Urval ${optimalUtil.getMaxAreaNumber(shapes)}`;
        let newShape: Shape | null = null;
        const circle = new google.maps.Circle({
          fillColor: color,
          strokeColor: color,
          map: mapRef.current,
          editable: false,
          ...optimalUtil.getCircleProps(markerData),
        });
        // create the shape and add to selection shape array
        newShape = optimalUtil.convertCircleToShape(circle, color, name);
        // create reference to the google shape to be able to adjust it later
        renderedGoogleShapesRef.current[newShape.uuid] = circle;
        // add new shape to shape arr
        addShape(newShape);
        setCurrentmarkerEditShape(newShape.uuid);
      } else {
        // if markercircle exist, update it with markerData radius, typically someone pressed radius up/down
        updateShape({
          uuid: currentMarkerEditShape,
          center: markerData.coordinates,
          radius: markerData.limit,
          type: 'circle',
        });
      }
    }
  };

  useEffect(() => {
    console.log('marker data and handle proximity radius');
    if (markerData?.proximityRadius && markerData?.type === 'PROXIMITY') {
      console.log('Proximity data hit.', markerData.type);
      handleProximityRadius();
    } else if (proximityCircle) {
      console.log('Removing proximity circle', markerData);
      proximityCircle.setMap(null);
      setProximityCircle(null);
    }
    if (markerData?.type === 'SELECTION') {
      createUpdateMarkerCircle();
    } else if (currentMarkerEditShape) {
      console.log('We got PROXIMITY but also currentMarker set');
      removeShape(currentMarkerEditShape);
      setCurrentmarkerEditShape(null);
    }
  }, [markerData, handleProximityRadius]);

  const renderGoogleMapShapes = useCallback(() => {
    console.log('Render all the google map shapes');
    if (mapRef.current) {
      shapes.forEach((shape) => {
        let googleShape:
          | google.maps.Polygon
          | google.maps.Circle
          | google.maps.Rectangle
          | null = null;
        if (shape.type === 'polygon' && shape.path) {
          googleShape = new google.maps.Polygon({
            paths: shape.path,
            fillColor: shape.color,
            strokeColor: shape.color,
            map: mapRef.current,
            editable: false,
          });
        } else if (shape.type === 'circle' && shape.center && shape.radius) {
          googleShape = new google.maps.Circle({
            center: shape.center,
            radius: shape.radius,
            fillColor: shape.color,
            strokeColor: shape.color,
            map: mapRef.current,
            editable: false,
          });
        }

        if (googleShape) {
          renderedGoogleShapesRef.current[shape.uuid] = googleShape;
        }
      });
    }
  }, [shapes]);

  useEffect(() => {
    console.log('Remove google map shapes, and print them again');
    clearGoogleMapShapes();
    renderGoogleMapShapes();
  }, [shapes, renderGoogleMapShapes]);

  const editMapShape = useCallback(() => {
    console.log('Handle edit shape');
    if (editShape) {
      // Start editing a shape
      setCurrentEditShape(editShape.uuid);
      renderedGoogleShapesRef.current[editShape.uuid].setOptions({
        editable: true,
      });
    } else if (currentEditShape) {
      // Finish editing the shape
      const editedShape = renderedGoogleShapesRef.current[currentEditShape];
      editedShape.setOptions({ editable: false });
      let updatedShape: Shape;
      if (editedShape instanceof google.maps.Polygon) {
        updatedShape = optimalUtil.convertPolygonToShape(editedShape, '', '');
      } else if (editedShape instanceof google.maps.Circle) {
        updatedShape = optimalUtil.convertCircleToShape(editedShape, '', '');
      } else {
        console.error('Unkown google shape:', editedShape);
        return;
      }
      updateShape({ ...updatedShape, uuid: currentEditShape });
      setCurrentEditShape(null);
    }
  }, [editShape, currentEditShape]);

  useEffect(() => {
    console.log('edit map shape');
    editMapShape();
  }, [editShape, editMapShape]);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      console.log('onload');
      mapRef.current = map;

      const initDrawingManager = () => {
        console.log('init drawing manager');
        if (!window.google || !google.maps.drawing) {
          console.error(
            'Google Maps JavaScript API or Drawing library not loaded',
          );
          return;
        }

        const drawingManagerOptions: google.maps.drawing.DrawingManagerOptions =
          {
            drawingControl: googleConfig.options.drawingControl,
            drawingControlOptions: {
              position:
                google.maps.ControlPosition[
                  googleConfig.options.drawingControlOptions
                    .position as keyof typeof google.maps.ControlPosition
                ],
              drawingModes: googleConfig.options.drawingControlOptions
                .drawingModes as google.maps.drawing.OverlayType[],
            },
            markerOptions: {
              draggable: true, // Optional: make the marker draggable
            },
          };

        drawingManagerRef.current = new google.maps.drawing.DrawingManager(
          drawingManagerOptions,
        );
        drawingManagerRef.current.setMap(map);

        google.maps.event.addListener(
          drawingManagerRef.current,
          'overlaycomplete',
          (event: google.maps.drawing.OverlayCompleteEvent) => {
            console.log('Overlay complete, shapes length:', shapes.length);
            const color = COLORS[colorIndexRef.current % COLORS.length];
            const name = `Urval ${optimalUtil.getMaxAreaNumber(shapes)}`;
            let newShape: Shape | null = null;

            if (event.type === 'polygon') {
              const polygon = event.overlay as google.maps.Polygon;
              optimalUtil.setShapeStyle(polygon, color); // Set style
              newShape = optimalUtil.convertPolygonToShape(
                polygon,
                color,
                name,
              ); // Convert polygon to Shape
            } else if (event.type === 'circle') {
              const circle = event.overlay as google.maps.Circle;
              optimalUtil.setShapeStyle(circle, color); // Set style
              newShape = optimalUtil.convertCircleToShape(circle, color, name); // Convert circle to Shape
            } else if (event.type === 'rectangle') {
              console.log('adding rectangle');
              const rectangle = event.overlay as google.maps.Rectangle;
              optimalUtil.setShapeStyle(rectangle, color); // Set style
              newShape = optimalUtil.convertRectangleToPolygonShape(
                rectangle,
                color,
                name,
              ); // Convert rectangle to polygon Shape
            } else if (event.type === 'marker') {
              if (mapMarkerRef.current === null) {
                const marker = event.overlay as google.maps.Marker;
                setMarkerData({
                  addressStr: '',
                  coordinates: optimalUtil.convertLatLngToCoordinates(
                    marker.getPosition(),
                  ),
                  limit: 100,
                  type: shapes.length > 0 ? 'SELECTION' : 'PROXIMITY',
                });
                /*if (marker.getPosition()) {
                  mapMarkerRef.current = new google.maps.Marker({
                    position: optimalUtil.convertLagLngToCoordinates(
                      marker.getPosition(),
                    ),
                    map: mapRef.current,
                    title: 'Adress',
                  });
                }*/
              }
            }

            // hide the drawn black figure:
            if (event.overlay) {
              event.overlay.setMap(null);
            }

            // Add new shape to the state, only if newShape is not null
            if (newShape !== null) {
              console.log('Adding new shape:', newShape, 'to', shapes);
              addShape(newShape);
            } else {
              console.log('Error, no shape?');
            }

            colorIndexRef.current += 1;
          },
        );
      };

      if (googleLoaded) {
        initDrawingManager();
        renderGoogleMapShapes();
      }
    },
    [googleLoaded, renderGoogleMapShapes],
  );

  const onUnmount = useCallback(() => {
    console.log('on unmount drawingManagerRef');
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }
  }, []);

  if (!googleLoaded) return <Spinner type="circle" />;

  const printShapes = (): String => {
    let result = `Shapes:`;
    shapes.forEach((shape, idx) => {
      result = result.concat(`===${idx}=== [${shape.name}, ${shape.type}] `);
    });
    return result;
  };

  return (
    <>
      <GoogleMap
        id="map"
        mapContainerClassName={styles.mapContainer}
        zoom={googleConfig.defaultZoom}
        center={googleConfig.defaultStartCoordinates}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
      <div>{markerData ? JSON.stringify(markerData) : 'no data'}</div>
      <div>{printShapes()}</div>
    </>
  );
};

export default OptimalGoogleMap;
