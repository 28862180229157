import React from 'react';
import { Shape } from '../../../models';
import styles from '../styles/Geo.module.scss';
import { MagnifierIcon, PlusCircleIcon, TrashAltIcon } from '../../misc/icons';
import Icon from '../../misc/Icon';

interface MapAreaListProps {
  storedShapes: Shape[];
  onPanToShape: (shape: Shape) => void;
  addShape: (shape: Shape) => void;
  removeStoredShape: (shape: Shape) => void;
}

const StoredMapAreaList: React.FC<MapAreaListProps> = ({
  storedShapes,
  onPanToShape,
  addShape,
  removeStoredShape,
}) => {
  return (
    <div className={styles.mapAreaList}>
      <h4>
        <Icon
          iconName="starOutline"
          color="#eb5f62"
          className={styles.mediumPlusIcon}
        />
        &nbsp;Sparade områden
      </h4>
      <ul>
        {storedShapes.map((shape, index) => {
          return (
            <li key={shape.uuid}>
              <div
                className={styles.omrade}
                style={{ color: shape.color, flexGrow: 1 }}
              >
                {shape.name}
              </div>
              <div
                onClick={() => addShape(shape)}
                className={styles.iconContainer}
              >
                <PlusCircleIcon />
              </div>
              <div
                onClick={() => onPanToShape(shape)}
                className={styles.iconContainer}
              >
                <MagnifierIcon />
              </div>
              <div
                onClick={() => removeStoredShape(shape)}
                className={styles.iconContainer}
              >
                <TrashAltIcon />
              </div>
            </li>
          );
        })}
        {storedShapes.length === 0 && <li>Inga favoriter skapade</li>}
      </ul>
    </div>
  );
};

export default StoredMapAreaList;
