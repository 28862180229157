import React from 'react';
import ToggleButton from '../interface/global/ToggleButton';
import styles from './styles/CriteriaGroup.module.scss';

interface HouseholdCompositionProps {
  householdComposition: ('E' | 'F' | 'X')[];
  active: boolean;
  setHouseholdComposition: (items: ('E' | 'F' | 'X')[]) => void;
}

const HouseholdComposition: React.FC<HouseholdCompositionProps> = ({
  householdComposition,
  active,
  setHouseholdComposition,
}) => {
  const handleSelect = (value: 'E' | 'F' | 'X') => {
    if (householdComposition.includes(value)) {
      setHouseholdComposition(
        householdComposition.filter((item) => item !== value),
      );
    } else {
      setHouseholdComposition([...householdComposition, value]);
    }
  };

  return (
    <>
      {active && (
        <>
          <h3>Välj hushållssammansättning:</h3>
          <div className={`${styles.iconButtonMenu} ${styles.marginBottom}`}>
            <ToggleButton
              label="Ensamhushåll"
              iconName="single"
              isSelected={householdComposition.includes('E')}
              onClick={() => handleSelect('E')}
            />
            <ToggleButton
              label="Familjehushåll"
              iconName="users"
              isSelected={householdComposition.includes('F')}
              onClick={() => handleSelect('F')}
            />
            <ToggleButton
              label="Okänt"
              iconName="unknown"
              isSelected={householdComposition.includes('X')}
              onClick={() => handleSelect('X')}
            />
          </div>
        </>
      )}
    </>
  );
};

export default HouseholdComposition;
