import axios, { AxiosRequestConfig } from 'axios';
import {
  AddressCountPayload,
  CriterionCountPayload,
  Exclusion,
  GeoCountPayload,
  MapArea,
  MapSettings,
  PhoneDeliveryDetails,
  ReferenceCopy,
  SearchResult,
  Selection,
  SelectionCount,
} from '../models';

const BASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api`;
const REGISTER_SESSION = `${BASE_URL}/registerSession`;
const GET_SELECTION = `${BASE_URL}/assignment/selection/get`;
const UPDATE_SELECTION = `${BASE_URL}/assignment/selection/update`;
const COUNT_FOR_GEO = `${BASE_URL}/assignment/countforgeo`;
const COUNT_FOR_ADDRESS = `${BASE_URL}/assignment/countforaddress`;
const COUNT_FOR_CRITERION = `${BASE_URL}/assignment/countforcriterion`;
const GET_MAP_AREAS = `${BASE_URL}/geo/getmapareas`;
const GET_MAP_SETTINGS = `${BASE_URL}/geo/getmapsettings`;
const POST_MAP_AREAS = `${BASE_URL}/geo/storemapareas`;
const GET_REF_COPY = `${BASE_URL}/assignment/refcopy/get`;
const CREATE_REF_COPY = `${BASE_URL}/assignment/refcopy/create`;
const DELETE_REF_COPY = `${BASE_URL}/assignment/refcopy/delete`;
const EDIT_REF_COPY = `${BASE_URL}/assignment/refcopy/update`;
const GET_ASSIGNMENT_COUNT = `${BASE_URL}/assignment/count/get`;
const UPDATE_ASSIGNMENT_COUNT = `${BASE_URL}/assignment/limit`;
const SEARCH_OPTIMAL = `${BASE_URL}/search`;
const SEARCH_OPTIMAL_BRF_ORGNR = `${BASE_URL}/search/brf/orgnr`;
const GET_EXCLUSION = `${BASE_URL}/assignment/exclusion/get`;
const CREATE_EXCLUSION = `${BASE_URL}/assignment/exclusion/create`;
const DELETE_EXCLUSION = `${BASE_URL}/assignment/exclusion/delete`;
const EDIT_EXCLUSION = `${BASE_URL}/assignment/exclusion/update`;
const UPLOAD_EXCLUSION = `${BASE_URL}/assignment/exclusion/upload`;
const GET_FOCUS_AREAS = `${BASE_URL}/focusarea/get`;
const GET_LOCATION = `${BASE_URL}/search/locations/get`;
const SET_PHONE_SELECTION = `${BASE_URL}/assignment/phoneselection/set/`;
const DELETE_PHONE_SELECTION = `${BASE_URL}/assignment/phoneselection/delete/`;

const getAxiosConfig = (
  url: string,
  method: 'GET' | 'POST',
  data?: any,
): AxiosRequestConfig => {
  const token = sessionStorage.getItem('authToken'); // Retrieve token from sessionStorage

  return {
    url,
    method,
    withCredentials: true,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }), // Add Authorization if token exists
    },
    ...(data && { data }), // Include data payload if provided
  };
};

export const getSelection = async (refNo: string): Promise<Selection> => {
  const config = getAxiosConfig(`${GET_SELECTION}/${refNo}`, 'GET');
  try {
    let result = await axios(config);
    // If the request is successful, return the result
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getSelection endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const updateSelection = async (
  selection: Selection,
  refNo: string,
): Promise<SelectionCount> => {
  try {
    selection.refNo = refNo;
    const config = getAxiosConfig(UPDATE_SELECTION, 'POST', selection);
    const result = await axios(config);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling updateSelection endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const registerSession = async (refNo: string): Promise<any> => {
  try {
    const config = getAxiosConfig(`${REGISTER_SESSION}/${refNo}`, 'GET');
    const result = await axios(config);
    console.log('Register assignment result: ', JSON.stringify(result.data));
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling registerSession endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const countForGeo = async (
  payload: GeoCountPayload,
): Promise<number> => {
  try {
    const config = getAxiosConfig(COUNT_FOR_GEO, 'POST', payload);
    const result = await axios(config);
    console.log('got count for geo result: ', result.data);
    return result.data.grossCount;
  } catch (error: any) {
    console.error(
      'Error calling countForGeo endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const countForAddress = async (
  payload: AddressCountPayload,
): Promise<number> => {
  try {
    const config = getAxiosConfig(COUNT_FOR_ADDRESS, 'POST', payload);
    const result = await axios(config);
    console.log('got count for address result: ', result.data);
    return result.data.grossCount;
  } catch (error: any) {
    console.error(
      'Error calling countForAddress endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const countForCriteria = async (
  payload: CriterionCountPayload,
): Promise<number> => {
  try {
    const config = getAxiosConfig(COUNT_FOR_CRITERION, 'POST', payload);
    const result = await axios(config);
    console.log('got count for criteria result: ', result.data);
    return result.data.grossCount;
  } catch (error: any) {
    console.error(
      'Error calling countForCriteria endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getMapAreas = async (refNo: string): Promise<MapArea[]> => {
  try {
    const config = getAxiosConfig(`${GET_MAP_AREAS}/${refNo}`, 'GET');
    const result = await axios(config);
    console.log(result.data);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getMapAreas endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const saveMapAreas = async (mapAreas: MapArea[], refNo: string) => {
  try {
    console.log(mapAreas);
    const config = getAxiosConfig(POST_MAP_AREAS, 'POST', {
      refNo: refNo,
      mapAreas: mapAreas,
    });
    const result = await axios(config);
    console.log(result);
  } catch (error: any) {
    console.error(
      'Error calling saveMapAreas endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getReferenceCopies = async (
  refNo: string,
): Promise<ReferenceCopy[]> => {
  const config = getAxiosConfig(`${GET_REF_COPY}/${refNo}`, 'GET');
  try {
    let result = await axios(config);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getSelection endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const addReferenceCopy = async (
  referenceCopy: ReferenceCopy,
  refNo: string,
) => {
  try {
    console.log(referenceCopy);
    const config = getAxiosConfig(CREATE_REF_COPY, 'POST', {
      referenceCopy: referenceCopy,
      refNo: refNo,
    });
    const result = await axios(config);
    return result.data.referenceCopy;
  } catch (error: any) {
    console.error(
      'Error calling addReferenceCopy endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const editReferenceCopy = async (
  referenceCopy: ReferenceCopy,
  refNo: string,
) => {
  try {
    console.log(referenceCopy);
    const config = getAxiosConfig(EDIT_REF_COPY, 'POST', {
      referenceCopy: referenceCopy,
      refNo: refNo,
    });
    const result = await axios(config);
    console.log('Result of edit', result);
    return result.data.referenceCopy;
  } catch (error: any) {
    console.error(
      'Error calling editReferenceCopy endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const deleteReferenceCopy = async (
  refNo: string,
  referenceCopy: ReferenceCopy,
) => {
  try {
    console.log(
      `Deleting reference copy with ID ${referenceCopy._id} from assignment ${refNo}`,
    );
    const config = getAxiosConfig(DELETE_REF_COPY, 'POST', {
      referenceCopy: referenceCopy,
      refNo: refNo,
    });
    const result = await axios(config);
    return result;
  } catch (error: any) {
    console.error(
      'Error calling deleteReferenceCopy endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getExclusions = async (refNo: string): Promise<Exclusion[]> => {
  const config = getAxiosConfig(`${GET_EXCLUSION}/${refNo}`, 'GET');
  try {
    let result = await axios(config);
    console.log('Get exclusion result: ', result);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getExclusions endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const addExclusion = async (exclusion: Exclusion, refNo: string) => {
  try {
    const config = getAxiosConfig(CREATE_EXCLUSION, 'POST', {
      exclusion: exclusion,
      refNo: refNo,
    });
    const result = await axios(config);
    return result.data.exclusion;
  } catch (error: any) {
    console.error(
      'Error calling addExclusion endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const editExclusion = async (exclusion: Exclusion, refNo: string) => {
  try {
    const config = getAxiosConfig(EDIT_EXCLUSION, 'POST', {
      exclusion: exclusion,
      refNo: refNo,
    });
    const result = await axios(config);
    console.log('Result of edit', result);
    return result.data.exclusion;
  } catch (error: any) {
    console.error(
      'Error calling editExclusion endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const deleteExclusion = async (refNo: string, exclusion: Exclusion) => {
  try {
    console.log(
      `Deleting exclusion with ID ${exclusion._id} from assignment ${refNo}`,
    );
    const config = getAxiosConfig(DELETE_EXCLUSION, 'POST', {
      exclusion: exclusion,
      refNo: refNo,
    });
    const result = await axios(config);
    return result;
  } catch (error: any) {
    console.error(
      'Error calling deleteExclusion endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getMapSettings = async (refNo: string): Promise<MapSettings> => {
  try {
    const config = getAxiosConfig(`${GET_MAP_SETTINGS}/${refNo}`, 'GET');
    const result = await axios(config);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getMapsettings endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getCount = async (refNo: string): Promise<Number> => {
  const config = getAxiosConfig(`${GET_ASSIGNMENT_COUNT}/${refNo}`, 'GET');
  try {
    let result = await axios(config);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getSelection endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const updateCount = async (count: Number, refNo: string) => {
  try {
    const config = getAxiosConfig(UPDATE_ASSIGNMENT_COUNT, 'POST', {
      count: count,
      refNo: refNo,
    });
    const result = await axios(config);
    console.log('Result of update count', result);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling editReferenceCopy endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

const searchOptimal = async (
  index: string,
  searchString: string,
): Promise<SearchResult[]> => {
  let type = undefined;
  if (
    index !== 'brf' &&
    index !== 'postort' &&
    index !== 'kommun' &&
    index !== 'locations'
  ) {
    throw new Error('Unknown index');
  } else {
    if (index === 'postort') {
      type = 'p';
      index = 'locations';
    } else if (index === 'kommun') {
      type = 'k';
      index = 'locations';
    }
  }

  // Dynamically build the data object
  const data: { query: string; type?: string } = {
    query: searchString,
    ...(type ? { type } : {}),
  };

  const config = getAxiosConfig(`${SEARCH_OPTIMAL}/${index}`, 'POST', data);

  try {
    const result = await axios(config);
    if (result.status === 200) {
      // Assuming result.data is the array of SearchResult
      return result.data as SearchResult[];
    } else {
      console.error('Unexpected status code:', result.status);
      return [];
    }
  } catch (error: any) {
    console.error('Error searching:', error.message || error);
    return [];
  }
};

const searchBRFOrgnr = async (orgnr: string): Promise<SearchResult | null> => {
  const config = getAxiosConfig(SEARCH_OPTIMAL_BRF_ORGNR, 'POST', {
    orgNo: orgnr,
  });
  try {
    const result = await axios(config);
    if (result.status === 200) {
      // Assuming result.data is the array of SearchResult
      if ((result.data as SearchResult[]).length > 0) {
        return (result.data as SearchResult[])[0];
      }
      return null;
    } else {
      console.error('Unexpected status code:', result.status);
      return null;
    }
  } catch (error: any) {
    console.error('Error searching:', error.message || error);
  }
  return null;
};

const getLocation = async (
  value: string,
  type: 'r' | 'k' | 'p',
): Promise<SearchResult | null> => {
  const config = getAxiosConfig(GET_LOCATION, 'POST', { value, type });
  try {
    const result = await axios(config);
    if (result.status === 200) {
      console.log('Got location:', result.data);
      return result.data as SearchResult;
    }
    return null;
  } catch (error: any) {
    console.error('Error fetching location data,', error);
  }
  return null;
};

const uploadExclusionFile = async (formData: FormData) => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      method: 'post',
      data: formData,
      url: UPLOAD_EXCLUSION,
    };
    const result = await axios(config);
    console.log('Result of exclusion file upload', result);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling editReferenceCopy endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const getFocusAreas = async (refNo: string): Promise<Exclusion[]> => {
  const config = getAxiosConfig(`${GET_FOCUS_AREAS}/${refNo}`, 'GET');
  try {
    let result = await axios(config);
    console.log('Get focusAreas result: ', result);
    return result.data;
  } catch (error: any) {
    console.error(
      'Error calling getExclusions endpoint:',
      error.message || error,
    );
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

const setPhoneSelection = async (
  refNo: string,
  phoneDetails: PhoneDeliveryDetails,
) => {
  const config = getAxiosConfig(
    `${SET_PHONE_SELECTION}/${refNo}`,
    'POST',
    phoneDetails,
  );
  try {
    let result = await axios(config);
    if (result.status !== 200) {
      throw new Error('Error: ' + result.status);
    }
  } catch (error: any) {
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

const deletePhoneSelection = async (refNo: string) => {
  const config = getAxiosConfig(`${DELETE_PHONE_SELECTION}/${refNo}`, 'POST');
  try {
    let result = await axios(config);
    if (result.status !== 200) {
      throw new Error('Error: ' + result.status);
    }
  } catch (error: any) {
    throw new Error(`Error calling endpoint: ${error.message || error}`);
  }
};

export const optimalAPI = {
  getMapAreas,
  countForGeo,
  countForAddress,
  countForCriteria,
  registerSession,
  getSelection,
  updateSelection,
  getMapSettings,
  saveMapAreas,
  searchOptimal,
  searchBRFOrgnr,
  uploadExclusionFile,
  getFocusAreas,
  getLocation,
  setPhoneSelection,
  deletePhoneSelection,
};
