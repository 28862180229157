import React from 'react';
import CheckboxLabel from '../interface/global/CheckboxLabel';
import styles from './styles/Housing.module.scss';

interface AcreageProps {
  acreage: string[];
  setAcreage: (value: string[]) => void;
}

const Acreage: React.FC<AcreageProps> = ({ acreage, setAcreage }) => {
  const handleChange = (value: string, checked: boolean) => {
    if (checked) {
      // Add to gender array if checked and not already included
      setAcreage([...acreage, value]);
    } else {
      // Remove from gender array if unchecked
      setAcreage(acreage.filter((item) => item !== value));
    }
  };

  return (
    <div className={styles.CriteriaGroup}>
      <div className={styles.criteriaLabel}>Välj tomtarea (m²):</div>
      <div className={`${styles.menu} `}>
        <CheckboxLabel
          checked={acreage.includes('0')}
          value="0"
          label="0–50"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('50')}
          value="50"
          label="50–100"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('100')}
          value="100"
          label="100–150"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('150')}
          value="150"
          label="150–200"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('200')}
          value="200"
          label="200–250"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('250')}
          value="250"
          label="250–300"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('300')}
          value="300"
          label="300–400"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('400')}
          value="400"
          label="400–500"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('500')}
          value="500"
          label="500–750"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('750')}
          value="750"
          label="750–1 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('1000')}
          value="1000"
          label="1 000–1 250"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('1250')}
          value="1250"
          label="1 250–1 500"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('1500')}
          value="1500"
          label="1 500–1 750"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('1750')}
          value="1750"
          label="1 750–2 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('2000')}
          value="2000"
          label="2 000–2 500"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('2500')}
          value="2500"
          label="2 500–3 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('3000')}
          value="3000"
          label="3 000–3 500"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('3500')}
          value="3500"
          label="3 500–4 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('4000')}
          value="4000"
          label="4 000–4 500"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('4500')}
          value="4500"
          label="4 500–5 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('5000')}
          value="5000"
          label="5 000–6 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('6000')}
          value="6000"
          label="6 000–7 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('7000')}
          value="7000"
          label="7 000–8 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('8000')}
          value="8000"
          label="8 000–9 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('9000')}
          value="9000"
          label="9 000–10 000"
          setValue={handleChange}
        />
        <CheckboxLabel
          checked={acreage.includes('10000')}
          value="10000"
          label="10 000+"
          setValue={handleChange}
        />
      </div>
    </div>
  );
};

export default Acreage;
