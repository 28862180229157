import React, { useState, useEffect, useRef } from 'react';
import { CheckIcon } from '../../misc/icons';
import styles from '../styles/Geo.module.scss';
import { ProximityMarkerSettings } from '../../../models';
import { isEqual } from 'lodash'; // Or you can use JSON.stringify for deep comparison

interface ProximitySettingsProps {
  show: boolean;
  limit: number;
  setSettings: (setting: ProximityMarkerSettings) => void;
}

const ProximitySettings: React.FC<ProximitySettingsProps> = ({
  show,
  limit,
  setSettings,
}) => {
  const [markerSetting, setMarkerSetting] = useState<ProximityMarkerSettings>({
    type: 'PROXIMITY',
    size: limit ? limit : 100,
    done: false,
  });

  useEffect(() => {
    console.log('show', show);
    setMarkerSetting({ ...markerSetting, done: !show });
  }, [show]);

  // useRef to store the previous markerSetting for comparison
  const prevMarkerSetting = useRef<ProximityMarkerSettings | null>(null);

  useEffect(() => {
    // Compare current and previous markerSetting
    if (!isEqual(markerSetting, prevMarkerSetting.current)) {
      setSettings(markerSetting); // Only call setSettings if something has changed
      prevMarkerSetting.current = markerSetting; // Update the ref to the new setting
    }
  }, [markerSetting, setSettings]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarkerSetting({
      ...markerSetting,
      type: event.target.value as 'PROXIMITY' | 'SELECTION',
    });
  };

  const handleSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (value) {
      setMarkerSetting({
        ...markerSetting,
        size: value,
      });
    }
  };

  const setDone = () => {
    setMarkerSetting({ ...markerSetting, done: true });
  };

  return (
    <>
      {show && (
        <div className={styles.proximitySettings}>
          <h3>Välj antalsbegränsning eller radie:</h3>
          <label>
            <input
              type="radio"
              value="PROXIMITY"
              checked={markerSetting.type === 'PROXIMITY'}
              onChange={handleOptionChange}
            />
            Antal
          </label>
          <label>
            <input
              type="radio"
              value="SELECTION"
              checked={markerSetting.type === 'SELECTION'}
              onChange={handleOptionChange}
            />
            Radie
          </label>
          <div className={styles.bottomRow}>
            <label>
              {markerSetting.type === 'PROXIMITY' && <div>Antal:</div>}
              {markerSetting.type === 'SELECTION' && <div>Radie (m):</div>}
              <input
                type="number"
                value={markerSetting.size}
                onChange={handleSize}
                min={10}
                step={10}
              />
            </label>
            <span className={styles.greenIcon} onClick={setDone}>
              <CheckIcon />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ProximitySettings;
