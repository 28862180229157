import React from 'react';
import CheckboxLabel from '../interface/global/CheckboxLabel';
import styles from './styles/CriteriaGroup.module.scss';

interface ConsumptionPowerProps {
  consumptionPower: ('K1' | 'K2' | 'K3' | 'K4' | 'K5')[];
  active: boolean;
  setConsumptionPower: (items: ('K1' | 'K2' | 'K3' | 'K4' | 'K5')[]) => void;
}

const ConsumptionPower: React.FC<ConsumptionPowerProps> = ({
  consumptionPower,
  active,
  setConsumptionPower,
}) => {
  const handleChange = (value: string, checked: boolean) => {
    const typedValue = value as 'K1' | 'K2' | 'K3' | 'K4' | 'K5';

    if (checked) {
      setConsumptionPower([...consumptionPower, typedValue]);
    } else {
      setConsumptionPower(
        consumptionPower.filter((item) => item !== typedValue),
      );
    }
  };

  return (
    <>
      {active && (
        <>
          <h3>Konsumtionskraft:</h3>
          <div className={styles.menu}>
            <CheckboxLabel
              checked={consumptionPower.includes('K1')}
              value="K1"
              label="Minst"
              setValue={handleChange}
            />
            <CheckboxLabel
              checked={consumptionPower.includes('K2')}
              value="K2"
              label="Liten"
              setValue={handleChange}
            />
            <CheckboxLabel
              checked={consumptionPower.includes('K3')}
              value="K3"
              label="Normal"
              setValue={handleChange}
            />
            <CheckboxLabel
              checked={consumptionPower.includes('K4')}
              value="K4"
              label="Hög"
              setValue={handleChange}
            />
            <CheckboxLabel
              checked={consumptionPower.includes('K5')}
              value="K5"
              label="Högst"
              setValue={handleChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConsumptionPower;
