import { useEffect, useState } from 'react';
import OptimalUrval from './components/OptimalUrval';
import { GlobalProvider } from './GlobalContext';
import Login from './Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('authToken'); // Clear sessionStorage
    sessionStorage.removeItem('refNo');
    setIsAuthenticated(false);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <GlobalProvider>
          <OptimalUrval />
        </GlobalProvider>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
