import { v4 as uuidv4 } from 'uuid';
import { COLORS, MenuItem } from '../conf/constants';
import {
  Coordinates,
  GeoCoordinates,
  GeoShape,
  MapArea,
  MarkerData,
  Shape,
} from '../models';

const isNonEmptyArray = (value: unknown): boolean => {
  return Array.isArray(value) && value.length > 0;
};

const convertPolygonToShape = (
  polygon: google.maps.Polygon,
  color: string,
  name: string,
): Shape => {
  return {
    uuid: uuidv4(),
    type: 'polygon',
    color,
    name,
    path: polygon
      .getPath()
      .getArray()
      .map((latLng: google.maps.LatLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      })),
  };
};

const convertCircleToShape = (
  circle: google.maps.Circle,
  color: string,
  name: string,
): Shape => {
  const center = circle.getCenter();
  const uuid = uuidv4();
  console.log('Generated new id (convert):', uuid);
  return {
    uuid: uuid,
    type: 'circle',
    color,
    name,
    center: center ? { lat: center.lat(), lng: center.lng() } : undefined,
    radius: circle.getRadius(),
  };
};

const convertRectangleToPolygonShape = (
  rectangle: google.maps.Rectangle,
  color: string,
  name: string,
): Shape | null => {
  const bounds = rectangle.getBounds();
  if (bounds) {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    const se = new google.maps.LatLng(sw.lat(), ne.lng());

    return {
      uuid: uuidv4(),
      type: 'polygon',
      color,
      name,
      path: [
        { lat: ne.lat(), lng: ne.lng() },
        { lat: nw.lat(), lng: nw.lng() },
        { lat: sw.lat(), lng: sw.lng() },
        { lat: se.lat(), lng: se.lng() },
      ],
    };
  }
  return null;
};

const setShapeStyle = (
  shape: google.maps.Polygon | google.maps.Circle | google.maps.Rectangle,
  color: string,
) => {
  shape.setOptions({
    fillColor: color,
    strokeColor: color,
  });
};

// Convert GeoShapes to Shapes
const convertGeoShapesToShapes = (geoShapes: GeoShape[]): Shape[] => {
  return geoShapes.map((geoShape, index) => {
    const color = COLORS[index % COLORS.length];
    index += 1;
    const name = `Urval ${index}`;
    return getShape(color, name, geoShape);
  });
};

function convertMapAreaToShape(mapAreas: MapArea[]): Shape[] {
  return mapAreas.map((mapArea, index) => {
    const color = COLORS[COLORS.length - 1 - (index % COLORS.length)];
    const { name, shape } = mapArea;
    return getShape(color, name, shape);
  });
}

function getShape(color: string, name: string, shape: GeoShape) {
  if (shape.polygon) {
    return {
      uuid: uuidv4(),
      type: 'polygon',
      path: shape.polygon.map((coord) => ({
        lat: coord.lat,
        lng: coord.lon,
      })),
      name: name,
      color: color,
    } as Shape;
  } else if (shape.circle) {
    const uuid = uuidv4();
    console.log('Generated new id (getShape):', uuid);
    return {
      uuid: uuid,
      type: 'circle',
      center: {
        lat: shape.circle.lat,
        lng: shape.circle.lon,
      },
      radius: shape.circle.radius,
      name: name,
      color: color,
    } as Shape;
  }
  return {} as Shape;
}

function convertShapeToMapArea(shapes: Shape[]): MapArea[] {
  return shapes.map((shape) => {
    const { name, type, path, center, radius } = shape;

    let geoShape: GeoShape = {};
    console.log('Shape convertion:', name, type, path, center, radius);
    if (type === 'polygon' && path) {
      geoShape.polygon = path.map((coord) => ({
        lat: coord.lat,
        lon: coord.lng,
      }));
    } else if (type === 'circle' && center && radius !== undefined) {
      geoShape.circle = {
        lat: center.lat,
        lon: center.lng,
        radius: radius,
      };
    }

    return {
      name: name || 'Unnamed Area', // Use a default name if none is provided
      shape: geoShape,
    } as MapArea;
  });
}
// Convert Shape to GeoShape
const convertShapeToGeoShape = (shapes: Shape[]): GeoShape[] => {
  return shapes.map((shape) => {
    if (shape.type === 'polygon' && shape.path) {
      return {
        polygon: shape.path.map((point) => ({
          lat: point.lat,
          lon: point.lng,
        })),
      };
    } else if (shape.type === 'circle' && shape.center && shape.radius) {
      return {
        circle: {
          lat: shape.center.lat,
          lon: shape.center.lng,
          radius: shape.radius,
        },
      };
    }
    return {} as GeoShape;
  });
};

const getShapeCenterCoordinates = (shape: Shape): Coordinates | null => {
  if (!window.google) {
    console.error('Google Maps JavaScript API not loaded');
    return null;
  }

  if (shape.type === 'polygon' && shape.path && shape.path.length > 0) {
    const path = shape.path.map(
      (point) => new google.maps.LatLng(point.lat, point.lng),
    );
    const polygon = new google.maps.Polygon({ paths: path });
    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((latLng) => bounds.extend(latLng));
    const center = bounds.getCenter();
    return { lat: center.lat(), lng: center.lng() };
  } else if (shape.type === 'circle' && shape.center) {
    return shape.center;
  }

  return null;
};

function getLatLngBoundsFromShape(shape: Shape): google.maps.LatLngBounds {
  if (!google.maps) {
    throw new Error('Google Maps JavaScript API is not loaded.');
  }

  const bounds = new google.maps.LatLngBounds();

  if (shape.type === 'polygon' && shape.path) {
    shape.path.forEach((coord) => {
      bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
    });
  } else if (
    shape.type === 'circle' &&
    shape.center &&
    shape.radius !== undefined
  ) {
    const center = new google.maps.LatLng(shape.center.lat, shape.center.lng);
    const circleBounds = new google.maps.Circle({
      center: center,
      radius: shape.radius,
    }).getBounds();

    if (circleBounds) {
      bounds.union(circleBounds);
    }
  }

  return bounds;
}

function getLatLngBoundsFromShapes(shapes: Shape[]): google.maps.LatLngBounds {
  if (!google.maps) {
    throw new Error('Google Maps JavaScript API is not loaded.');
  }

  const bounds = new google.maps.LatLngBounds();

  shapes.forEach((shape) => {
    const shapeBounds = getLatLngBoundsFromShape(shape);
    bounds.union(shapeBounds);
  });

  return bounds;
}

const getMaxAreaNumber = (shapes: Shape[]) => {
  let maxNumber = 0;
  shapes.forEach((shape) => {
    if (shape.name) {
      const match = shape.name.match(/^Urval (\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        if (number > maxNumber) {
          maxNumber = number;
        }
      }
    }
  });
  return maxNumber + 1;
};

const convertGeoCoordinates = (coord: GeoCoordinates): Coordinates => {
  return { lat: coord.lat, lng: coord.lon };
};

const convertCoordinates = (coord: Coordinates): GeoCoordinates => {
  return { lat: coord.lat, lon: coord.lng };
};

const convertLatLngToCoordinates = (
  latlng: google.maps.LatLng | null | undefined,
): Coordinates => {
  if (latlng) return { lat: latlng.lat(), lng: latlng.lng() };
  // This will not happen - object secured but type is defined by google to above.
  return { lat: 0, lng: 0 };
};

const getCircleProps = (
  markerData: MarkerData,
): { radius: number; center: { lat: number; lng: number } } => {
  let typeConfig;
  if (markerData.type === 'PROXIMITY') {
    typeConfig = {
      radius: markerData.proximityRadius ? markerData.proximityRadius : 0,
    };
  } else {
    typeConfig = {
      radius: markerData.limit,
    };
  }
  return {
    ...typeConfig,
    center: {
      lat: markerData.coordinates.lat,
      lng: markerData.coordinates.lng,
    },
  };
};

const getCircleBounds = (center: Coordinates, radius: number) => {
  // Earth's radius in meters
  const earthRadius = 6371000;

  // Calculate the north and south latitudes within the radius
  const latOffset = (radius / earthRadius) * (180 / Math.PI);
  const northLat = center.lat + latOffset;
  const southLat = center.lat - latOffset;

  // Calculate the east and west longitudes within the radius
  const lngOffset =
    (radius / (earthRadius * Math.cos((center.lat * Math.PI) / 180))) *
    (180 / Math.PI);
  const eastLng = center.lng + lngOffset;
  const westLng = center.lng - lngOffset;

  // Create LatLngBounds object using the calculated points
  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(southLat, westLng), // SW corner
    new google.maps.LatLng(northLat, eastLng), // NE corner
  );

  return bounds;
};

const isSameCoordinates = (
  coord1: google.maps.LatLng | null | undefined,
  coord2: Coordinates,
): boolean => {
  if (coord1 && coord2) {
    return coord1.lat() === coord2.lat && coord1.lng() === coord2.lng;
  } else return false;
};

const isEqualCoordinates = (
  coord1: Coordinates,
  coord2: Coordinates,
): boolean => {
  if (coord1 && coord2) {
    return coord1.lat === coord2.lat && coord1.lng === coord2.lng;
  }
  return false;
};

function isSameShape(gShape: GeoShape, shape: Shape): boolean {
  // Helper function to compare polygon paths
  const isSamePath = (
    path1: Coordinates[],
    path2: GeoCoordinates[],
  ): boolean => {
    if (path1.length !== path2.length) {
      return false;
    }

    return path1.every((coord, index) =>
      isEqualCoordinates(coord, {
        lat: path2[index].lat,
        lng: path2[index].lon,
      }),
    );
  };

  if (shape.type === 'polygon' && gShape.polygon) {
    if (!shape.path) return false;
    return isSamePath(shape.path, gShape.polygon);
  } else if (shape.type === 'circle' && gShape.circle) {
    if (!shape.center || shape.radius === undefined) return false;
    return (
      shape.radius === gShape.circle.radius &&
      isEqualCoordinates(shape.center, {
        lat: gShape.circle.lat,
        lng: gShape.circle.lon,
      })
    );
  }

  return false;
}

function getMenuItemLabel(menuItem: string): string {
  const label = MenuItem[menuItem as keyof typeof MenuItem];
  return label ? label : 'Ingen label';
}

function getLocationTypeCode(type: string): 'r' | 'k' | 'p' {
  if (type === 'Region' || type === 'r') {
    return 'r';
  }
  if (type === 'Kommun' || type === 'k') {
    return 'k';
  }
  if (type === 'Postort' || type === 'p') {
    return 'p';
  }
  throw Error('Unknown type code');
}

function getLocationTypeCodeName(type: string): string {
  if (type === 'Region' || type === 'r') {
    return 'Region';
  }
  if (type === 'Kommun' || type === 'k') {
    return 'Kommun';
  }
  if (type === 'Postort' || type === 'p') {
    return 'Postort';
  }
  if (type === 'z') {
    return 'Postnummer';
  }
  throw Error('Unknown type code');
}

function getLocationCriterion(type: string, exclude: boolean): string {
  if (type === 'r') {
    return (exclude ? 'excludingC' : 'c') + 'ounty';
  }
  if (type === 'k') {
    return (exclude ? 'excludingM' : 'm') + 'unicipality';
  }
  if (type === 'p') {
    return (exclude ? 'excludingC' : 'c') + 'ity';
  }
  if (type === 'z') {
    return (exclude ? 'excludingZ' : 'z') + 'ip';
  }
  throw Error('Unknown type code' + type);
}

export const optimalUtil = {
  getShapeCenterCoordinates,
  convertShapeToGeoShape,
  convertGeoShapesToShapes,
  convertMapAreaToShape,
  isNonEmptyArray,
  getMaxAreaNumber,
  getLatLngBoundsFromShape,
  getLatLngBoundsFromShapes,
  convertGeoCoordinates,
  convertCoordinates,
  convertLatLngToCoordinates,
  convertShapeToMapArea,
  convertPolygonToShape,
  convertCircleToShape,
  convertRectangleToPolygonShape,
  setShapeStyle,
  getCircleProps,
  getCircleBounds,
  isSameCoordinates,
  isEqualCoordinates,
  isSameShape,
  getMenuItemLabel,
  getLocationTypeCode,
  getLocationTypeCodeName,
  getLocationCriterion,
};
