import React from 'react';
import styles from './styles/Spinner.module.scss';

type SpinnerProps = {
  type?: 'circle' | 'dots';
  color?: 'red' | 'white';
};

const Spinner: React.FC<SpinnerProps> = ({
  type = 'circle',
  color = 'red',
}) => {
  return (
    <div>
      {type === 'circle' && <div className={styles.spinner}></div>}
      {type === 'dots' && (
        <div
          className={`${styles.threeDotsSpinner} ${color === 'white' ? styles.white : ''}`}
        >
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      )}
    </div>
  );
};

export default Spinner;
