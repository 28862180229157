import React from 'react';

const TelemarketingInstructions: React.FC = () => (
  <div>
    <p>
      Här kan du lämna <strong>instruktioner och önskemål för uppdraget</strong>
      . Vanliga frågor vi behöver få svar på är:
    </p>
    <ul>
      <li>Startdatum och deadline?</li>
      <li>När hålls aktiviteten?</li>
      <li>När kan vi boka möten?</li>
      <li>
        Finns det något speciellt som utmärker mottagarna vi ska ringa till?
      </li>
      <li>Övrigt som är bra att veta om?</li>
      <li>Ska några specifika adresser inkluderas/exkluderas?</li>
      <li>När vill ni ha statistik?</li>
      <li>Olika kontaktpersoner?</li>
    </ul>
    <p>
      <strong>Exempel:</strong>
    </p>
    <p>
      Boka möten med våra mäklare likt tidigare. Ring mellan
      <strong>1/3</strong> och <strong>20/3</strong>. Möten skall bokas tidigast
      <strong>tre arbetsdagar fram</strong> och inte innan <strong>7/3</strong>.
      Boka inget <strong>9/3</strong>. Första mötet med mäklaren tar ca 40
      minuter och kan hållas mellan kl. <strong>10-20</strong>.
    </p>
    <p>
      Mottagarna denna gång är framförallt <strong>unga barnfamiljer</strong>{' '}
      som är intresserade av att köpa villa. Vi har nyligen sålt
      <strong>två andra bostadsrätter</strong> i samma förening för
      <strong>rekordhöga priser</strong> och har många intressenter kvar från
      tidigare budgivning.
    </p>
    <p>
      <strong>Exkludera:</strong> Storgatan 1 och Storgatan 15 från
      marknadsföring.
      <br />
      <strong>Skicka statistik:</strong> Fredagar kl. 16.00.
    </p>
  </div>
);

export default TelemarketingInstructions;
